import React, { useEffect, useState } from 'react';

import { Search as SearchIcon } from 'react-feather';
import dayjs from 'dayjs';

import {
  Box,
  Button,
  Card,
  CardContent,
  InputAdornment,
  SvgIcon,
  TextField,
} from '@material-ui/core';

import Select from 'react-select';

import {
  DatePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import {
  DEFAULT_ROW_PER_PAGE,
} from 'src/utils/constants';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import { LstracuuAction } from 'src/actions/lstracuu.action';
import { AccountAction } from 'src/actions/account.acction';

import moment from 'moment';
import { saveAs } from 'file-saver';
import { AppAction } from 'src/actions/app.action';
import * as XLSX from 'xlsx';

const LstracuuListToolbar = (props) => {
  const dispatch = useDispatch();
  const { filter } = useSelector(state => state.lstracuu)
  const { items, total } = useSelector(state => state.account)
  const { info, permissions, role } = useSelector(state => state.profile)
  const [values, setValues] = useState({
    username: "",
    noi_dung: "",
    startDate: dayjs(filter.startDate, moment(new Date()).format('DD/MM/YYYY')),
    endDate: dayjs(filter.endDate, moment(new Date()).format('DD/MM/YYYY')),
  });

  const handleStartDateChange = (date) => {

    setValues({
      ...values,
      startDate: date
    });
  };

  const handleEndDateChange = (date) => {
    setValues({
      ...values,
      endDate: date
    });
  };

  const handleNoiDungChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleUsernameChange1 = (name, newValue) => {
    setValues({
      ...values,
      [name]: newValue
    });
  };

  useEffect(() => {
    const today = new Date();

    const payload = {
      page: 0,
      limit: DEFAULT_ROW_PER_PAGE,
      username: "",
      noi_dung: "",
      startDate: dayjs(new Date(today.getFullYear(), today.getMonth(), 1)).format("DD/MM/YYYY"),
      endDate: dayjs(today).format("DD/MM/YYYY"),
    };
    dispatch(LstracuuAction.updateFilter(payload));
    dispatch(AccountAction.listNoLimit());
  }, []);

  const onLookup = () => {
    const payload = {
      page: 'no_update',
      limit: 'no_update',
      username: values.username,
      noi_dung: values.noi_dung,
      startDate: values.startDate.format("DD/MM/YYYY"),
      endDate: values.endDate.format("DD/MM/YYYY"),
    };
    dispatch(LstracuuAction.updateFilter(payload));
    dispatch(LstracuuAction.list())
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      onLookup();
    }
  };

  const xuatFile = async () => {
    if (role.priority == 5) return;
    dispatch(AppAction.appLoading(true))
    try {
      const payload = {
        getAll: true,
        username: values.username,
        noi_dung: values.noi_dung,
        startDate: values.startDate.format("DD/MM/YYYY"),
        endDate: values.endDate.format("DD/MM/YYYY"),
      };
      dispatch(LstracuuAction.updateFilter(payload));
      const response = await dispatch(LstracuuAction.list());
      console.log(response)

      const data = [
        [
          '#', 
          'Username nhân viên', 
          'Tên nhân viên', 
          'Số điện thoại', 
          'Nội dung tra cứu',
          'Thời điểm tra cứu',
        ],
      ];

      if (typeof response !== 'undefined' && response != null && typeof response.data !== 'undefined' && response.data && typeof response.data.values !== 'undefined' && response.data.values && response.data.values.length > 0) {
        var count = 1;
        response.data.values.forEach((element) => {
          data.push([
            count,
            element.username,
            element.full_name,
            role.priority < 3 ? element.so_dien_thoai.slice(-9) : ('****' + element.so_dien_thoai.slice(-5)),
            element.noi_dung,
            moment(element.created_at).format('DD-MM-YYYY HH:mm:ss'),
          ])
          count++;
        })

        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.aoa_to_sheet(data);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
        
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, 'LICH-SU-TRA-CUU.xlsx');
      }

      dispatch(AppAction.appLoading(false))
    } catch (error) {
      if (typeof error.code !== 'undefined' && typeof error.msg !== 'undefined') alert("[Lỗi]\n"+error.msg)
    }
  }

  return (
    <Box {...props}>
      <Box sx={{ mt: 3 }}>
        <Card className="filter_card" style={{overflow: 'visible'}}>
          <CardContent>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Box sx={{ 
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}>
                <div style={{ width: '250px', zIndex: '10' }} className="full-width-select">
                  <Select
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        height: 56,
                        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                      }),
                      option: (provided) => ({
                        ...provided,
                        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                      }),
                    }}
                    options={items}
                    getOptionLabel={(item) => `${item.role.priority == 4 ? '--' : (item.role.priority == 5 ? '----' : '')} ${item.username} - ${item.fullname}`}
                    placeholder={values.username || "Chọn nhân viên..."}
                    components={{
                      DropdownIndicator: () => <SearchIcon size={16} color="action" />,
                      IndicatorSeparator: null,
                    }}
                    value={values.username || null}
                    onChange={(newValue) => handleUsernameChange1('username', newValue.username)}
                    isSearchable
                  />
                </div>
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon
                          fontSize="small"
                          color="action"
                          >
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    )
                  }}
                  name="noi_dung"
                  onChange={handleNoiDungChange}
                  onKeyDown={handleKeyDown}
                  value={values.noi_dung}
                  placeholder="Tìm nội dung"
                  variant="outlined"
                />
                <DatePicker
                  label="Thời gian bắt đầu"
                  style={{ width: '100%', marginRight: 24 }}
                  format='DD/MM/YYYY'
                  value={values.startDate}
                  onChange={(newValue) => handleStartDateChange(newValue)}
                />
                <DatePicker
                  label="Thời gian kết thúc"
                  style={{ width: '100%', marginLeft: 24 }}
                  value={values.endDate}
                  format='DD/MM/YYYY'
                  onChange={(newValue) => handleEndDateChange(newValue)}
                />
                {role.priority != 5 && <Button
                  color="primary"
                  variant="contained"
                  onClick={xuatFile}
                  style={{ height: 56, marginTop: 0, width: "150px", marginLeft: 0 }}
                >
                  Xuất file
                </Button>}
                <Button
                  color="primary"
                  variant="contained"
                  onClick={onLookup}
                  style={{ height: 56, marginTop: 0, width: 200, marginLeft: 24 }}
                  >
                  Tra cứu
                </Button>
              </Box>
            </LocalizationProvider>
          </CardContent>
        </Card>
      </Box>
    </Box>
  )
};

export default LstracuuListToolbar;
