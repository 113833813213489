import { QuetdatakhonggoiService } from 'src/services/quetdatakhonggoi.service';
import { showToast } from 'src/utils/common';
import { NOTI_TYPES } from 'src/utils/constants';

import { AppAction } from './app.action';

export const QUETDATAKHONGGOI_NEW_CARE_SUCCESS = 'QUETDATAKHONGGOI:NEW_CARE_SUCCESS'
export const QUETDATAKHONGGOI_LIST_SUCCESS = 'QUETDATAKHONGGOI:FIND_ALL_SUCCESS'
export const QUETDATAKHONGGOI_LIST_FAILD = 'QUETDATAKHONGGOI:FIND_ALL_FAILD'
export const QUETDATAKHONGGOI_COUNT_ASSIGN_SUCCESS = 'QUETDATAKHONGGOI:COUNT_ASSIGN_SUCCESS'
export const QUETDATAKHONGGOI_COUNT_ASSIGN_FAILD = 'QUETDATAKHONGGOI:COUNT_ASSIGN_FAILD'

export const QUETDATAKHONGGOI_CREATE_SUCCESS = 'QUETDATAKHONGGOI:CREATE_SUCCESS'
export const QUETDATAKHONGGOI_CREATE_FAILD = 'QUETDATAKHONGGOI:CREATE_FAILD'

export const QUETDATAKHONGGOI_IMPORT_SUCCESS = 'QUETDATAKHONGGOI:IMPORT_SUCCESS'
export const QUETDATAKHONGGOI_SAVE_SUCCESS = 'QUETDATAKHONGGOI:SAVE_SUCCESS'
export const QUETDATAKHONGGOI_SAVE_FAILD = 'QUETDATAKHONGGOI:SAVE_SUCCESS'

export const QUETDATAKHONGGOI_DEL_SUCCESS = 'QUETDATAKHONGGOI:DEL_SUCCESS'
export const QUETDATAKHONGGOI_DEL_FAILD = 'QUETDATAKHONGGOI:DEL_FAILD'

export const QUETDATAKHONGGOI_ONE_SUCCESS = 'QUETDATAKHONGGOI:FIND_ONE_SUCCESS'
export const QUETDATAKHONGGOI_ONE_FAILD = 'QUETDATAKHONGGOI:FIND_ONE_FAILD'

export const QUETDATAKHONGGOI_HISTORY_LIST_SUCCESS = 'QUETDATAKHONGGOI:HISTORY_FIND_ALL_SUCCESS'
export const QUETDATAKHONGGOI_HISTORY_LIST_FAILD = 'QUETDATAKHONGGOI:HISTORY_FIND_ALL_FAILD'

export const QUETDATAKHONGGOI_LIST_BY_FILENAME_SUCCESS = 'QUETDATAKHONGGOI:FIND_ALL_BY_FILENAME_SUCCESS'
export const QUETDATAKHONGGOI_LIST_BY_FILENAME_FAILD = 'QUETDATAKHONGGOI:FIND_ALL_BY_FILENAME_FAILD'

export const QUETDATAKHONGGOI_INFO_BY_FILENAME_SUCCESS = 'QUETDATAKHONGGOI:INFO_BY_FILENAME_SUCCESS'
export const QUETDATAKHONGGOI_INFO_BY_FILENAME_FAILD = 'QUETDATAKHONGGOI:INFO_BY_FILENAME_FAILD'

export const QUETDATAKHONGGOI_UPDATE_FILTER = 'QUETDATAKHONGGOI:UPDATE_FILTER'

export const QuetdatakhonggoiAction = {
    list,
    findAssign,
    create,
    importData,
    saveData,
    update,
    del,
    assign,
    countAssign,
    history,
    findOne,
    saveDataUpload,
    findAllByFileName,
    updateFilter,
    deleteFile,
    getInfoByFileName,
}

function updateFilter(payload) {
    return { type: QUETDATAKHONGGOI_UPDATE_FILTER, data: payload }
}

function deleteFile(FileName) {
    return (dispatch) => {
        dispatch(AppAction.appLoading(true));
        QuetdatakhonggoiService.deleteFile(FileName).then((rs) => {
            showToast(NOTI_TYPES.SUCCESS, "Xóa file '" + FileName + "' thành công!");
        }).catch((error) => {
            showToast(NOTI_TYPES.ERROR, "Xóa file '" + FileName + "' thất bại!");
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
}

function countAssign() {
    return (dispatch) => {
        dispatch(AppAction.appLoading(true));
        QuetdatakhonggoiService.countAssign().then((rs) => {
            dispatch(success(rs.data.total));
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: QUETDATAKHONGGOI_COUNT_ASSIGN_SUCCESS, data } }
    function failure(error) { return { type: QUETDATAKHONGGOI_COUNT_ASSIGN_FAILD, error } }
}

function findAssign(page = 0, count = 20) {
    return (dispatch, getState) => {
        const { quetdatakhonggoi } = getState()
        dispatch(AppAction.appLoading(true));
        QuetdatakhonggoiService.findAssign(page, count).then((rs) => {
            if (page === 0) {
                dispatch(success({
                    items: rs.data.values,
                    total: rs.data.count
                }));
            } else {
                dispatch(success({
                    items: [...quetdatakhonggoi.items, ...rs.data.values],
                    total: rs.data.count
                }));
            }
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: QUETDATAKHONGGOI_LIST_SUCCESS, data } }
    function failure(error) { return { type: QUETDATAKHONGGOI_LIST_FAILD, error } }
}

function list(page = 0, count = 20,) {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        const { quetdatakhonggoi } = getState();
        const page = quetdatakhonggoi && typeof quetdatakhonggoi.filter !== 'undefined' && quetdatakhonggoi.filter && typeof quetdatakhonggoi.filter.page !== 'undefined' ? quetdatakhonggoi.filter.page : 0;
        const limit = quetdatakhonggoi && typeof quetdatakhonggoi.filter !== 'undefined' && quetdatakhonggoi.filter && typeof quetdatakhonggoi.filter.limit !== 'undefined' ? quetdatakhonggoi.filter.limit : 10;
        QuetdatakhonggoiService.findAll(page, limit).then((rs) => {
            if (page === 0) {
                dispatch(success({
                    items: rs.data.values,
                    total: rs.data.count
                }));
            } else {
                const oldItems = quetdatakhonggoi.items;
                const rsDataValues = rs.data.values;

                var uniqueItems = oldItems;
                for (var i = limit * page; i < limit * page + limit; i++) {
                    if (typeof rsDataValues[i - limit * page] !== 'undefined') {
                        uniqueItems[i] = rsDataValues[i - limit * page];
                    } else if (typeof uniqueItems[i] !== 'undefined') {
                        delete uniqueItems[i];
                    }
                }
                
                dispatch(success({
                    items: uniqueItems,
                    total: rs.data.count
                }));
            }
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: QUETDATAKHONGGOI_LIST_SUCCESS, data } }
    function failure(error) { return { type: QUETDATAKHONGGOI_LIST_FAILD, error } }
}

function getInfoByFileName(payload) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            QuetdatakhonggoiService.getInfoByFileName(payload)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    }
    function success(data) { return { type: QUETDATAKHONGGOI_INFO_BY_FILENAME_SUCCESS, data } }
    function failure(error) { return { type: QUETDATAKHONGGOI_INFO_BY_FILENAME_FAILD, error } }
}

function findAllByFileName(payload) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            QuetdatakhonggoiService.findAllByFileName(payload)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    }
    function success(data) { return { type: QUETDATAKHONGGOI_LIST_BY_FILENAME_SUCCESS, data } }
    function failure(error) { return { type: QUETDATAKHONGGOI_LIST_BY_FILENAME_FAILD, error } }
}

function importData(payload) {
    return { type: QUETDATAKHONGGOI_IMPORT_SUCCESS, data: payload }
}

function saveData(payload) {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        let count = 0
        for (let i = 0; i < payload.length; i += 200) {
            // eslint-disable-next-line no-loop-func
            QuetdatakhonggoiService.save(payload.slice(i, i + 200)).then((rs) => {
                count += parseInt(rs.data.saved, 10)
                if (count === payload.length) {
                    dispatch(success(rs));
                    dispatch(QuetdatakhonggoiAction.list())
                    dispatch(AppAction.appLoading(false));
                }
            }).catch((error) => {
                dispatch(failure(error));
                dispatch(AppAction.appLoading(false));
            })
        }
    };

    function success(data) { return { type: QUETDATAKHONGGOI_SAVE_SUCCESS, data } }
    function failure(error) { return { type: QUETDATAKHONGGOI_SAVE_FAILD, error } }
}

function create(payload) {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        QuetdatakhonggoiService.create(payload).then((rs) => {
            dispatch(success(rs));
            dispatch(QuetdatakhonggoiAction.list())
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: QUETDATAKHONGGOI_CREATE_SUCCESS, data } }
    function failure(error) { return { type: QUETDATAKHONGGOI_CREATE_FAILD, error } }
}

function update(id, payload) {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        QuetdatakhonggoiService.update(id, payload).then((rs) => {
            dispatch(success(rs));
            dispatch(QuetdatakhonggoiAction.list())
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: QUETDATAKHONGGOI_CREATE_SUCCESS, data } }
    function failure(error) { return { type: QUETDATAKHONGGOI_CREATE_FAILD, error } }
}

function del(id) {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        QuetdatakhonggoiService.delete(id).then((rs) => {
            dispatch(success(rs));
            dispatch(QuetdatakhonggoiAction.list())
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: QUETDATAKHONGGOI_DEL_SUCCESS, data } }
    function failure(error) { return { type: QUETDATAKHONGGOI_DEL_FAILD, error } }
}

function findOne(id) {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        QuetdatakhonggoiService.find(id).then((rs) => {
            dispatch(success(rs.data));
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: QUETDATAKHONGGOI_ONE_SUCCESS, data } }
    function failure(error) { return { type: QUETDATAKHONGGOI_ONE_FAILD, error } }
}

function assign(payload) {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        QuetdatakhonggoiService.assign(payload).then((rs) => {
            showToast('success', "Phân chia dữ liệu thành công.")
            dispatch(QuetdatakhonggoiAction.list())
        }).catch((error) => {
            showToast('error', "Phân chia dữ liệu thất bại.\nVui lòng thử lại lần sau.")
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
}

function history(payload) {
    return (dispatch, getState) => {
        const { quetdatakhonggoi } = getState()
        dispatch(AppAction.appLoading(true));
        QuetdatakhonggoiService.history(payload).then((rs) => {
            if (payload.page === 0) {
                dispatch(success({
                    items: rs.data.values,
                    total: rs.data.count
                }));
            } else {
                dispatch(success({
                    items: [...quetdatakhonggoi.items, ...rs.data.values],
                    total: rs.data.count
                }));
            }
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: QUETDATAKHONGGOI_HISTORY_LIST_SUCCESS, data } }
    function failure(error) { return { type: QUETDATAKHONGGOI_HISTORY_LIST_FAILD, error } }
}

function saveDataUpload(payload) {
    return (dispatch) => {
        dispatch(AppAction.appLoading(true));
        let count = 0
        for (let i = 0; i < payload.length; i += 200) {
            // eslint-disable-next-line no-loop-func
            QuetdatakhonggoiService.save(payload.slice(i, i + 200)).then((rs) => {
                count += parseInt(rs.data.saved, 10)
                if (count === payload.length) {
                    dispatch(success(rs));
                    dispatch(AppAction.appLoading(false));
                }
            }).catch((error) => {
                dispatch(failure(error));
                dispatch(AppAction.appLoading(false));
            })
        }
    };

    function success(data) { return { type: QUETDATAKHONGGOI_SAVE_SUCCESS, data } }
    function failure(error) { return { type: QUETDATAKHONGGOI_SAVE_FAILD, error } }
}