import { Helmet } from 'react-helmet';
import ChiadataDetail from 'src/components/chiadata-detail/ChiadataDetail';
import {
  Box,
  Container,
} from '@material-ui/core';

const Chiadata = () => (
  <>
    <Helmet>
      <title>Chia data</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container style={{maxWidth: "100%"}}>
        <Box sx={{ pt: 3 }}>
          <ChiadataDetail />
        </Box>
      </Container>
    </Box>
  </>
);

export default Chiadata;
