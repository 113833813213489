import { GiagoimfyService } from 'src/services/giagoimfy.service';
import { showToast } from 'src/utils/common';
import { NOTI_TYPES } from 'src/utils/constants';

import { AppAction } from './app.action';

export const GIAGOIMFY_NEW_CARE_SUCCESS = 'GIAGOIMFY:NEW_CARE_SUCCESS'
export const GIAGOIMFY_LIST_SUCCESS = 'GIAGOIMFY:FIND_ALL_SUCCESS'
export const GIAGOIMFY_LIST_FAILD = 'GIAGOIMFY:FIND_ALL_FAILD'
export const GIAGOIMFY_COUNT_ASSIGN_SUCCESS = 'GIAGOIMFY:COUNT_ASSIGN_SUCCESS'
export const GIAGOIMFY_COUNT_ASSIGN_FAILD = 'GIAGOIMFY:COUNT_ASSIGN_FAILD'

export const GIAGOIMFY_CREATE_SUCCESS = 'GIAGOIMFY:CREATE_SUCCESS'
export const GIAGOIMFY_CREATE_FAILD = 'GIAGOIMFY:CREATE_FAILD'

export const GIAGOIMFY_IMPORT_SUCCESS = 'GIAGOIMFY:IMPORT_SUCCESS'
export const GIAGOIMFY_SAVE_SUCCESS = 'GIAGOIMFY:SAVE_SUCCESS'
export const GIAGOIMFY_SAVE_FAILD = 'GIAGOIMFY:SAVE_SUCCESS'

export const GIAGOIMFY_DEL_SUCCESS = 'GIAGOIMFY:DEL_SUCCESS'
export const GIAGOIMFY_DEL_FAILD = 'GIAGOIMFY:DEL_FAILD'

export const GIAGOIMFY_ONE_SUCCESS = 'GIAGOIMFY:FIND_ONE_SUCCESS'
export const GIAGOIMFY_ONE_FAILD = 'GIAGOIMFY:FIND_ONE_FAILD'

export const GIAGOIMFY_HISTORY_LIST_SUCCESS = 'GIAGOIMFY:HISTORY_FIND_ALL_SUCCESS'
export const GIAGOIMFY_HISTORY_LIST_FAILD = 'GIAGOIMFY:HISTORY_FIND_ALL_FAILD'

export const GIAGOIMFY_UPDATE_FILTER = 'GIAGOIMFY:GIAGOIMFY_UPDATE_FILTER'

export const GIAGOIMFY_LIST_EXPORT_SUCCESS = 'GIAGOIMFY:FIND_ALL_EXPORT_SUCCESS'
export const GIAGOIMFY_LIST_EXPORT_FAILD = 'GIAGOIMFY:FIND_ALL_EXPORT_FAILD'

export const GIAGOIMFY_LIST_NO_LIMIT_SUCCESS = 'GIAGOIMFY:FIND_ALL_NO_LIMIT_SUCCESS'
export const GIAGOIMFY_LIST_NO_LIMIT_FAILD = 'GIAGOIMFY:FIND_ALL_NO_LIMIT_FAILD'

export const GiagoimfyAction = {
    list,
    findAssign,
    create,
    importData,
    saveData,
    update,
    del,
    assign,
    countAssign,
    history,
    findOne,
    updateFilter,
    saveDataUpload,
    findPackage,
    listExport,
    listNoLimit,
}

function updateFilter(payload) {
    return { type: GIAGOIMFY_UPDATE_FILTER, data: payload }
}

function listNoLimit() {
    return (dispatch, getState) => {
      const { giagoimfy } = getState()
      dispatch(AppAction.appLoading(true));
      GiagoimfyService.listNoLimit().then((rs) => {
        dispatch(success({
            items: rs.data.values,
            total: rs.data.count
        }));
      }).catch((error) => {
        dispatch(failure(error));
      }).finally(() => {
        dispatch(AppAction.appLoading(false));
      })
    };
    function success(data) { return { type: GIAGOIMFY_LIST_NO_LIMIT_SUCCESS, data } }
    function failure(error) { return { type: GIAGOIMFY_LIST_NO_LIMIT_FAILD, error } }
  }

function countAssign() {
    return (dispatch) => {
        dispatch(AppAction.appLoading(true));
        GiagoimfyService.countAssign().then((rs) => {
            dispatch(success(rs.data.total));
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: GIAGOIMFY_COUNT_ASSIGN_SUCCESS, data } }
    function failure(error) { return { type: GIAGOIMFY_COUNT_ASSIGN_FAILD, error } }
}

function findAssign(page = 0, count = 20) {
    return (dispatch, getState) => {
        const { giagoimfy } = getState()
        dispatch(AppAction.appLoading(true));
        GiagoimfyService.findAssign(page, count).then((rs) => {
            if (page === 0) {
                dispatch(success({
                    items: rs.data.values,
                    total: rs.data.count
                }));
            } else {
                dispatch(success({
                    items: [...giagoimfy.items, ...rs.data.values],
                    total: rs.data.count
                }));
            }
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: GIAGOIMFY_LIST_SUCCESS, data } }
    function failure(error) { return { type: GIAGOIMFY_LIST_FAILD, error } }
}

function saveDataUpload(payload) {
    return (dispatch) => {
        dispatch(AppAction.appLoading(true));
        let count = 0
        for (let i = 0; i < payload.length; i += 200) {
            // eslint-disable-next-line no-loop-func
            GiagoimfyService.save(payload.slice(i, i + 200)).then((rs) => {
                count += parseInt(rs.data.saved, 10)
                if (count === payload.length) {
                    dispatch(success(rs));
                    dispatch(AppAction.appLoading(false));
                }
            }).catch((error) => {
                dispatch(failure(error));
                dispatch(AppAction.appLoading(false));
            })
        }
    };

    function success(data) { return { type: GIAGOIMFY_SAVE_SUCCESS, data } }
    function failure(error) { return { type: GIAGOIMFY_SAVE_FAILD, error } }
}

function findPackage(ten_goi_cuoc) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            GiagoimfyService.findPackage(ten_goi_cuoc)
            .then(response => {
                // Handle the successful response
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                reject(error); // Reject the promise with the error
            });
        });
    };
}

function listExport() {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const { giagoimfy } = getState();

            const { filter } = giagoimfy;

            // Perform your asynchronous operation (e.g., API call)
            GiagoimfyService.listExport(filter)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    }
    function success(data) { return { type: GIAGOIMFY_LIST_EXPORT_SUCCESS, data } }
    function failure(error) { return { type: GIAGOIMFY_LIST_EXPORT_FAILD, error } }
}

function list() {
    return (dispatch, getState) => {
        const { giagoimfy } = getState();
        dispatch(AppAction.appLoading(true));

        const { filter } = giagoimfy;
        const page = filter.page;
        const limit = filter.limit;

        return new Promise((resolve, reject) => {
            GiagoimfyService.findAll(filter.page, filter.limit, filter).then((rs) => {
                if (!!filter && filter.page === 0) {
                    dispatch(success({
                        items: rs.data.values,
                        total: rs.data.count,
                    }));
                } else {
                    const oldItems = giagoimfy.items;
                    const rsDataValues = rs.data.values;

                    var uniqueItems = oldItems;
                    for (var i = limit * page; i < limit * page + limit; i++) {
                        if (typeof rsDataValues[i - limit * page] !== 'undefined') {
                            uniqueItems[i] = rsDataValues[i - limit * page];
                        } else if (typeof uniqueItems[i] !== 'undefined') {
                            delete uniqueItems[i];
                        }
                    }
                    
                    dispatch(success({
                        items: uniqueItems,
                        total: rs.data.count,
                    }));
                }
                resolve(rs);
            }).catch((error) => {
                dispatch(failure(error));
                reject(error);
            }).finally(() => {
                dispatch(AppAction.appLoading(false));
            })
        });
    };
    function success(data) { return { type: GIAGOIMFY_LIST_SUCCESS, data } }
    function failure(error) { return { type: GIAGOIMFY_LIST_FAILD, error } }
}

function importData(payload) {
    return { type: GIAGOIMFY_IMPORT_SUCCESS, data: payload }
}

function saveData(payload) {
    return (dispatch) => {
        dispatch(AppAction.appLoading(true));
        let count = 0
        for (let i = 0; i < payload.length; i += 200) {
            // eslint-disable-next-line no-loop-func
            GiagoimfyService.save(payload.slice(i, i + 200)).then((rs) => {
                count += parseInt(rs.data.saved, 10)
                if (count === payload.length) {
                    dispatch(success(rs));
                    dispatch(GiagoimfyAction.list())
                    dispatch(AppAction.appLoading(false));
                }
            }).catch((error) => {
                dispatch(failure(error));
                dispatch(AppAction.appLoading(false));
            })
        }
    };

    function success(data) { return { type: GIAGOIMFY_SAVE_SUCCESS, data } }
    function failure(error) { return { type: GIAGOIMFY_SAVE_FAILD, error } }
}

function create(payload) {
    return dispatch => {
        dispatch(AppAction.appLoading(true));
        GiagoimfyService.create(payload).then((rs) => {
            dispatch(success(rs));
            dispatch(GiagoimfyAction.list())
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: GIAGOIMFY_CREATE_SUCCESS, data } }
    function failure(error) { return { type: GIAGOIMFY_CREATE_FAILD, error } }
}

function update(id, payload) {
    return dispatch => {
        dispatch(AppAction.appLoading(true));
        GiagoimfyService.update(id, payload).then((rs) => {
            dispatch(success(rs));
            dispatch(GiagoimfyAction.list())
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: GIAGOIMFY_CREATE_SUCCESS, data } }
    function failure(error) { return { type: GIAGOIMFY_CREATE_FAILD, error } }
}

function del(id) {
    return dispatch => {
        dispatch(AppAction.appLoading(true));
        GiagoimfyService.delete(id).then((rs) => {
            dispatch(success(rs));
            dispatch(GiagoimfyAction.list())
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: GIAGOIMFY_DEL_SUCCESS, data } }
    function failure(error) { return { type: GIAGOIMFY_DEL_FAILD, error } }
}

function findOne(id) {
    return (dispatch) => {
        dispatch(AppAction.appLoading(true));
        GiagoimfyService.find(id).then((rs) => {
            dispatch(success(rs.data));
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: GIAGOIMFY_ONE_SUCCESS, data } }
    function failure(error) { return { type: GIAGOIMFY_ONE_FAILD, error } }
}

function assign(payload) {
    return dispatch => {
        dispatch(AppAction.appLoading(true));
        GiagoimfyService.assign(payload).then((rs) => {
            showToast('success', "Phân chia dữ liệu thành công.")
            dispatch(GiagoimfyAction.list())
        }).catch((error) => {
            showToast('error', "Phân chia dữ liệu thất bại.\nVui lòng thử lại lần sau.")
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
}


function history(payload) {
    return (dispatch, getState) => {
        const { giagoimfy } = getState()
        dispatch(AppAction.appLoading(true));
        GiagoimfyService.history(payload).then((rs) => {
            if (payload.page === 0) {
                dispatch(success({
                    items: rs.data.values,
                    total: rs.data.count
                }));
            } else {
                dispatch(success({
                    items: [...giagoimfy.items, ...rs.data.values],
                    total: rs.data.count
                }));
            }
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: GIAGOIMFY_HISTORY_LIST_SUCCESS, data } }
    function failure(error) { return { type: GIAGOIMFY_HISTORY_LIST_FAILD, error } }
}