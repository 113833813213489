import { Helmet } from 'react-helmet';
import MfyDetail
  from 'src/components/mfy-detail/MfyDetail';

import {
  Box,
  Container,
} from '@material-ui/core';

import MfyListToolbar from '../components/mfy-detail/MfyToolbar';

const Mfy = () => (
  <>
    <Helmet>
      <title>Mfy</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container style={{maxWidth: "100%"}}>
        <MfyListToolbar />
        <Box sx={{ pt: 3 }}>
          <MfyDetail />
        </Box>
      </Container>
    </Box>
  </>
);

export default Mfy;
