import {
    useEffect,
    useState,
} from 'react';

import {
    useDispatch,
    useSelector,
} from 'react-redux';
import { TinhluongAction } from 'src/actions/tinhluong.action';
import { ProfileAction } from 'src/actions/profile.action';
import { RoleAction } from 'src/actions/role.acction';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
} from '@material-ui/core';

const TinhluongEditDialog = (props) => {
    const dispatch = useDispatch()
    const { selected } = useSelector(state => state.tinhluong)

    const [values, setValues] = useState({
        thangTinhLuong: '',
        username: '',
        ngayCongThucTe: 0.0,
        luongHocViec: 0.0,
        hoaHongDichVu: 0.0,
        hoaHongSim: 0.0,
        thiDuaThang: 0.0,
        thuongKhuyenKhich: 0.0,
        thuongLeTet: 0.0,
        hoTroBaoHiemXaHoi: 0.0,
        nhanKhac: 0.0,
        baoHiemXaHoi: 0.0,
        cheTaiSaiNoiQuyCongTy: 0.0,
        cheTaiSaiNghiepVu: 0.0,
        cheTaiKhieuNai: 0.0,
        cheTaiKhac: 0.0,
        kyQuyTamUng: 0.0,
    });

    const resetData = () => {
        setValues({
            thangTinhLuong: '',
            username: '',
            ngayCongThucTe: 0.0,
            luongHocViec: 0.0,
            hoaHongDichVu: 0.0,
            hoaHongSim: 0.0,
            thiDuaThang: 0.0,
            thuongKhuyenKhich: 0.0,
            thuongLeTet: 0.0,
            hoTroBaoHiemXaHoi: 0.0,
            nhanKhac: 0.0,
            baoHiemXaHoi: 0.0,
            cheTaiSaiNoiQuyCongTy: 0.0,
            cheTaiSaiNghiepVu: 0.0,
            cheTaiKhieuNai: 0.0,
            cheTaiKhac: 0.0,
            kyQuyTamUng: 0.0,
        })
    }

    useEffect(() => {
        if (selected) {
            console.log(selected);
            setValues({
                thangTinhLuong: selected.thangTinhLuong,
                username: selected.username,
                ngayCongThucTe: selected.ngayCongThucTe,
                luongHocViec: selected.luongHocViec,
                hoaHongDichVu: selected.hoaHongDichVu,
                hoaHongSim: selected.hoaHongSim,
                thiDuaThang: selected.thiDuaThang,
                thuongKhuyenKhich: selected.thuongKhuyenKhich,
                thuongLeTet: selected.thuongLeTet,
                hoTroBaoHiemXaHoi: selected.hoTroBaoHiemXaHoi,
                nhanKhac: selected.nhanKhac,
                baoHiemXaHoi: selected.baoHiemXaHoi,
                cheTaiSaiNoiQuyCongTy: selected.cheTaiSaiNoiQuyCongTy,
                cheTaiSaiNghiepVu: selected.cheTaiSaiNghiepVu,
                cheTaiKhieuNai: selected.cheTaiKhieuNai,
                cheTaiKhac: selected.cheTaiKhac,
                kyQuyTamUng: selected.kyQuyTamUng,
            });
        } else {
            props.handleClose()
        }
    }, [selected])

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    };

    const handleUpdate = () => {
        dispatch(TinhluongAction.updateTinhLuong(values))
        resetData()
        props.handleClose()
    }

    return (
        <Dialog open={props.open} onClose={props.handleClose}>
            <DialogTitle>Cập nhật người dùng <span style={{color: 'blue'}}>{selected && typeof selected.username !== 'undefined' ? selected.username : ''}</span> <span style={{color: 'red', fontWeight: 'bold'}}>({selected && typeof selected.thangTinhLuong !== 'undefined' ? selected.thangTinhLuong : ''})</span></DialogTitle>
            <DialogContent style={{ paddingTop: 20 }}>
                <Grid
                    container
                    spacing={3}
                >
                    <Grid
                        item
                        md={6}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            label="Ngày công thực tế"
                            name="ngayCongThucTe"
                            onChange={handleChange}
                            required
                            value={values.ngayCongThucTe}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid
                        item
                        md={6}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            label="Lương học việc"
                            name="luongHocViec"
                            onChange={handleChange}
                            required
                            value={values.luongHocViec}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid
                        item
                        md={6}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            label="HH dịch vụ"
                            name="hoaHongDichVu"
                            onChange={handleChange}
                            required
                            value={values.hoaHongDichVu}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid
                        item
                        md={6}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            label="HH sim"
                            name="hoaHongSim"
                            onChange={handleChange}
                            required
                            value={values.hoaHongSim}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid
                        item
                        md={6}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            label="Thi đua tháng"
                            name="thiDuaThang"
                            onChange={handleChange}
                            required
                            value={values.thiDuaThang}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid
                        item
                        md={6}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            label="Thưởng KK"
                            name="thuongKhuyenKhich"
                            onChange={handleChange}
                            required
                            value={values.thuongKhuyenKhich}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid
                        item
                        md={6}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            label="Thưởng lễ/ Tết"
                            name="thuongLeTet"
                            onChange={handleChange}
                            required
                            value={values.thuongLeTet}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid
                        item
                        md={6}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            label="PCTN/ Hỗ trợ BHXH"
                            name="hoTroBaoHiemXaHoi"
                            onChange={handleChange}
                            required
                            value={values.hoTroBaoHiemXaHoi}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid
                        item
                        md={6}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            label="Nhận khác"
                            name="nhanKhac"
                            onChange={handleChange}
                            required
                            value={values.nhanKhac}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid
                        item
                        md={6}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            label="BHXH"
                            name="baoHiemXaHoi"
                            onChange={handleChange}
                            required
                            value={values.baoHiemXaHoi}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid
                        item
                        md={6}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            label="Chế tài sai nội quy công ty"
                            name="cheTaiSaiNoiQuyCongTy"
                            onChange={handleChange}
                            required
                            value={values.cheTaiSaiNoiQuyCongTy}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid
                        item
                        md={6}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            label="Chế tài sai nghiệp vụ"
                            name="cheTaiSaiNghiepVu"
                            onChange={handleChange}
                            required
                            value={values.cheTaiSaiNghiepVu}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid
                        item
                        md={6}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            label="Chế tài khiếu nại"
                            name="cheTaiKhieuNai"
                            onChange={handleChange}
                            required
                            value={values.cheTaiKhieuNai}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid
                        item
                        md={6}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            label="Chế tài khác"
                            name="cheTaiKhac"
                            onChange={handleChange}
                            required
                            value={values.cheTaiKhac}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid
                        item
                        md={6}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            label="Ký quỹ/ tạm ứng"
                            name="kyQuyTamUng"
                            onChange={handleChange}
                            required
                            value={values.kyQuyTamUng}
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose}>Huỷ</Button>
                <Button onClick={handleUpdate}>Cập nhật</Button>
            </DialogActions>
        </Dialog >
    );
}

export default TinhluongEditDialog;