import { toQuery } from 'src/utils/utils';

import { handleResponse } from '../utils/auth-header';
import axios, { axiosOption } from '../utils/axios';

export const Customermessage9084Service = {
  findAll: (page, count, payload) => {
    let url = `/api/v1/customermessage9084?page=${page}&count=${count}`;

    if (payload.message) {
        url += `&message=${payload.message}`
    };

    if (payload.username) {
        url += `&username=${payload.username}`
    };

    if (payload.startDate && payload.endDate) {
        url += `&startDate=${payload.startDate}&endDate=${payload.endDate}`
    }

    if (payload.subagent) {
        url += `&subagent=${payload.subagent}`
    };
    
    return axios.get(url, axiosOption()).then((rs) => {
        return handleResponse(rs)
    }).catch((error) => {
        throw handleResponse(error.response)
    })
  },
}