import { LstracuuService } from 'src/services/lstracuu.service';
import { showToast } from 'src/utils/common';
import {
    DEFAULT_ROW_PER_PAGE,
  } from 'src/utils/constants';

import { AppAction } from './app.action';

export const LSTRACUU_UPDATE_FILTER = 'LSTRACUU:LSTRACUU_UPDATE_FILTER'

export const LSTRACUU_NEW_CARE_SUCCESS = 'LSTRACUU:NEW_CARE_SUCCESS'
export const LSTRACUU_LIST_SUCCESS = 'LSTRACUU:FIND_ALL_SUCCESS'
export const LSTRACUU_LIST_FAILD = 'LSTRACUU:FIND_ALL_FAILD'
export const LSTRACUU_COUNT_ASSIGN_SUCCESS = 'LSTRACUU:COUNT_ASSIGN_SUCCESS'
export const LSTRACUU_COUNT_ASSIGN_FAILD = 'LSTRACUU:COUNT_ASSIGN_FAILD'

export const LSTRACUU_CREATE_SUCCESS = 'LSTRACUU:CREATE_SUCCESS'
export const LSTRACUU_CREATE_FAILD = 'LSTRACUU:CREATE_FAILD'

export const LSTRACUU_IMPORT_SUCCESS = 'LSTRACUU:IMPORT_SUCCESS'
export const LSTRACUU_SAVE_SUCCESS = 'LSTRACUU:SAVE_SUCCESS'
export const LSTRACUU_SAVE_FAILD = 'LSTRACUU:SAVE_SUCCESS'

export const LSTRACUU_DEL_SUCCESS = 'LSTRACUU:DEL_SUCCESS'
export const LSTRACUU_DEL_FAILD = 'LSTRACUU:DEL_FAILD'


export const LSTRACUU_HISTORY_LIST_SUCCESS = 'LSTRACUU:HISTORY_FIND_ALL_SUCCESS'
export const LSTRACUU_HISTORY_LIST_FAILD = 'LSTRACUU:HISTORY_FIND_ALL_FAILD'

export const LstracuuAction = {
    list,
    findAssign,
    create,
    updateFilter,
    importData,
    saveData,
    update,
    del,
    assign,
    countAssign,
    history,
}


function countAssign() {
    return (dispatch) => {
        dispatch(AppAction.appLoading(true));
        LstracuuService.countAssign().then((rs) => {
            dispatch(success(rs.data.total));
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: LSTRACUU_COUNT_ASSIGN_SUCCESS, data } }
    function failure(error) { return { type: LSTRACUU_COUNT_ASSIGN_FAILD, error } }
}

function findAssign(page = 0, count = 20) {
    return (dispatch, getState) => {
        const { lstracuu } = getState()
        dispatch(AppAction.appLoading(true));
        LstracuuService.findAssign(page, count).then((rs) => {
            if (page === 0) {
                dispatch(success({
                    items: rs.data.values,
                    total: rs.data.count,
                }));
            } else {
                dispatch(success({
                    items: [...lstracuu.items, ...rs.data.values],
                    total: rs.data.count,
                }));
            }
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: LSTRACUU_LIST_SUCCESS, data } }
    function failure(error) { return { type: LSTRACUU_LIST_FAILD, error } }
}

function list() {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        const { lstracuu } = getState();
        const page = lstracuu && typeof lstracuu.filter !== 'undefined' && lstracuu.filter && typeof lstracuu.filter.page !== 'undefined' ? lstracuu.filter.page : 0;
        const limit = lstracuu && typeof lstracuu.filter !== 'undefined' && lstracuu.filter && typeof lstracuu.filter.limit !== 'undefined' ? lstracuu.filter.limit : 10;
        
        return new Promise((resolve, reject) => {
            LstracuuService.findAll(page, limit, lstracuu.filter).then((rs) => {
                if (page === 0) {
                    dispatch(success({
                        items: rs.data.values,
                        total: rs.data.count,
                        countByName: rs.data.countByName,
                    }));
                } else {
                    const oldItems = lstracuu.items;
                    const rsDataValues = rs.data.values;

                    var uniqueItems = oldItems;
                    for (var i = limit * page; i < limit * page + limit; i++) {
                        if (typeof rsDataValues[i - limit * page] !== 'undefined') {
                            uniqueItems[i] = rsDataValues[i - limit * page];
                        } else if (typeof uniqueItems[i] !== 'undefined') {
                            delete uniqueItems[i];
                        }
                    }
                    
                    dispatch(success({
                        items: uniqueItems,
                        total: rs.data.count,
                        countByName: rs.data.countByName,
                    }));
                }
                resolve(rs);
            }).catch((error) => {
                dispatch(failure(error));
                reject(error);
            }).finally(() => {
                dispatch(AppAction.appLoading(false));
            })
        })
    };
    function success(data) { return { type: LSTRACUU_LIST_SUCCESS, data } }
    function failure(error) { return { type: LSTRACUU_LIST_FAILD, error } }
}

function updateFilter(payload) {
    return { type: LSTRACUU_UPDATE_FILTER, data: payload }
}

function importData(payload) {
    return { type: LSTRACUU_IMPORT_SUCCESS, data: payload }
}

function saveData(payload) {
    return (dispatch) => {
        dispatch(AppAction.appLoading(true));
        let count = 0
        for (let i = 0; i < payload.length; i += 200) {
            // eslint-disable-next-line no-loop-func
            LstracuuService.save(payload.slice(i, i + 200)).then((rs) => {
                count += parseInt(rs.data.saved, 10)
                if (count === payload.length) {
                    dispatch(success(rs));
                    dispatch(LstracuuAction.list())
                    dispatch(AppAction.appLoading(false));
                }
            }).catch((error) => {
                dispatch(failure(error));
                dispatch(AppAction.appLoading(false));
            })
        }
    };

    function success(data) { return { type: LSTRACUU_SAVE_SUCCESS, data } }
    function failure(error) { return { type: LSTRACUU_SAVE_FAILD, error } }
}

function update(payload) {
    return dispatch => {
        dispatch(AppAction.appLoading(true));
        LstracuuService.create(payload).then((rs) => {
            dispatch(success(rs));
            dispatch(LstracuuAction.list())
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: LSTRACUU_CREATE_SUCCESS, data } }
    function failure(error) { return { type: LSTRACUU_CREATE_FAILD, error } }
}


function create(payload) {
    return dispatch => {
        //dispatch(AppAction.appLoading(true));
        LstracuuService.create(payload).then((rs) => {
            dispatch(success(rs));
            //dispatch(LstracuuAction.list())
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            //(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: LSTRACUU_CREATE_SUCCESS, data } }
    function failure(error) { return { type: LSTRACUU_CREATE_FAILD, error } }
}

function del(id) {
    return dispatch => {
        dispatch(AppAction.appLoading(true));
        LstracuuService.delete(id).then((rs) => {
            dispatch(success(rs));
            dispatch(LstracuuAction.list())
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: LSTRACUU_DEL_SUCCESS, data } }
    function failure(error) { return { type: LSTRACUU_DEL_FAILD, error } }
}


function assign(payload) {
    return dispatch => {
        dispatch(AppAction.appLoading(true));
        LstracuuService.assign(payload).then((rs) => {
            showToast('success', "Phân chia dữ liệu thành công.")
            dispatch(LstracuuAction.list())
        }).catch((error) => {
            showToast('error', "Phân chia dữ liệu thất bại.\nVui lòng thử lại lần sau.")
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
}


function history(payload) {
    return (dispatch, getState) => {
        const { lstracuu } = getState()
        dispatch(AppAction.appLoading(true));
        LstracuuService.history(payload).then((rs) => {
            if (payload.page === 0) {
                dispatch(success({
                    items: rs.data.values,
                    total: rs.data.count,
                }));
            } else {
                dispatch(success({
                    items: [...lstracuu.items, ...rs.data.values],
                    total: rs.data.count
                }));
            }
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: LSTRACUU_HISTORY_LIST_SUCCESS, data } }
    function failure(error) { return { type: LSTRACUU_HISTORY_LIST_FAILD, error } }
}