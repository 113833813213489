import io from 'socket.io-client';

import {
  deleteAllCookies,
  getCookie,
} from '../utils/common';

const socket = io("https://sys.dvmobifone.com", { rejectUnauthorized: false });

socket.on("connect", () => {
  console.log("Đã kết nối với máy chủ");
});

socket.on("disconnect", () => {
  console.log("Mất kết nối với máy chủ");
});

const MainSocket = (state) => {
  const http = window.location.port ? 'http://' : 'https://';
  const domain = window.location.hostname + (window.location.port ? ("_" + window.location.port) : '');
  const host = http + domain;
  const token = getCookie('accessToken');
  const username_nv = state.profile.info.username
  const socket_client_id = domain + "_" + username_nv;

  socket.emit("vas-client", {
    index: socket_client_id,
    host: host,
    token: token,
  });

  return { host, token, socket, socket_client_id, domain, username_nv };
};

export default MainSocket;