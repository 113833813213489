import {
  useEffect,
  useState,
} from 'react';
import {
  Edit3 as Edit3Icon,
  Lock,
  RefreshCcw,
  Trash2 as Trash2Icon,
  Unlock,
} from 'react-feather';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { BaodoAction } from 'src/actions/baodo.action';
import {
  CONFIRM_TYPES,
  DEFAULT_ROW_PER_PAGE,
  ROW_PER_PAGE_OPTIONS,
} from 'src/utils/constants';
import { getBaodoStatus } from 'src/utils/utils';
import ConfirmDialog from '../ConfirmDialog';
import {
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  IconButton,
  Button,
  Select,
  MenuItem,
} from '@material-ui/core';
import CreateEditDialog from './CreateEditDialog';

import numeral from 'numeral';

const BaodoLookupDetail = ({ ...rest }) => {
  const dispatch = useDispatch()
  const [selectedBaodoIds, setSelectedBaodoIds] = useState([]);
  const [limit, setLimit] = useState(DEFAULT_ROW_PER_PAGE);
  const [page, setPage] = useState(0);
  const { role } = useSelector(state => state.profile);
  const { items, total, filter } = useSelector(state => state.baodo);
  const [confirm, setConfirm] = useState(false);
  const [confirmType, setConfirmType] = useState("");
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [selected, setSelected] = useState({});
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const payload = {
      ...filter,
      limit: limit,
      page: page,
    };
    dispatch(BaodoAction.updateFilter(payload));
    dispatch(BaodoAction.list(page, limit))
  }, [page, limit])

  const handleSelectAll = (event) => {
    let newSelectedBaodoIds;

    if (event.target.checked) {
      newSelectedBaodoIds = items.map((baodo) => baodo.id);
    } else {
      newSelectedBaodoIds = [];
    }

    setSelectedBaodoIds(newSelectedBaodoIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedBaodoIds.indexOf(id);
    let newSelectedBaodoIds = [];

    if (selectedIndex === -1) {
      newSelectedBaodoIds = newSelectedBaodoIds.concat(selectedBaodoIds, id);
    } else if (selectedIndex === 0) {
      newSelectedBaodoIds = newSelectedBaodoIds.concat(selectedBaodoIds.slice(1));
    } else if (selectedIndex === selectedBaodoIds.length - 1) {
      newSelectedBaodoIds = newSelectedBaodoIds.concat(selectedBaodoIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedBaodoIds = newSelectedBaodoIds.concat(
        selectedBaodoIds.slice(0, selectedIndex),
        selectedBaodoIds.slice(selectedIndex + 1)
      );
    }

    setSelectedBaodoIds(newSelectedBaodoIds);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleConfirmOpen = (type, baodo) => {
    setSelected(baodo);
    setConfirmType(type);
    if (confirmType === CONFIRM_TYPES.DELETE) {
      setTitle("Xoá bản ghi")
      setContent(`Xác nhận xoá bản ghi `)
    }
    setConfirm(true);
  };

  const onConfirm = () => {
    setConfirm(false);
    if (confirmType === CONFIRM_TYPES.DELETE) {
      dispatch(BaodoAction.del(selected.id))
      return;
    }
  }

  const handleConfirmClose = () => {
    setConfirm(false);
  };

  const onSelected = (id) => {
    dispatch(BaodoAction.findOne(id));
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const approve = async (id, trang_thai_duyet) => {
    const question = trang_thai_duyet == 1 ? "Xác nhận duyệt đơn này?" : "Xác nhận từ chối đơn này?" ;
    if (window.confirm(question)) {
      try {
        const response = await dispatch(BaodoAction.approve(id, { trang_thai_duyet }));
        if (typeof response.channel !== 'undefined') alert("[Thông báo từ '"+response.channel+"']\n"+response.message+(response.status != "Unknown" ? (" ("+response.status+")") : ""));
        else if (typeof response.code !== 'undefined' && typeof response.msg !== 'undefined') alert("[Lỗi]\n"+response.msg)
      } catch (error) {
        if (typeof error.code !== 'undefined' && typeof error.msg !== 'undefined') alert("[Lỗi]\n"+error.msg)
      }
    } else {
      
    }
  };

  const setTrangThai = async (id, trang_thai) => {
    const question = trang_thai == 'SUCCESS' ? "Xác nhận đặt trạng thái 'Thành công'?" : "Xác nhận đặt trạng thái 'Thất bại'?" ;
    if (window.confirm(question)) {
      try {
        const response = await dispatch(BaodoAction.setTrangThai(id, { trang_thai }));
        if (typeof response.channel !== 'undefined') alert("[Thông báo từ '"+response.channel+"']\n"+response.message+(response.status != "Unknown" ? (" ("+response.status+")") : ""));
        else if (typeof response.code !== 'undefined' && typeof response.msg !== 'undefined') alert("[Lỗi]\n"+response.msg)
      } catch (error) {
        if (typeof error.code !== 'undefined' && typeof error.msg !== 'undefined') alert("[Lỗi]\n"+error.msg)
      }
    } else {
      
    }
  };

  return (
    <Card {...rest} style={{ width: "100%"}}>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  #
                </TableCell>
                <TableCell>
                  Số điện thoại
                </TableCell>
                <TableCell>
                  Gói cước
                </TableCell>
                <TableCell>
                  Kênh
                </TableCell>
                <TableCell>
                  Ngày thành công
                </TableCell>
                <TableCell>
                  Nhân viên
                </TableCell>
                <TableCell>
                  Lý do
                </TableCell>
                <TableCell>
                  Trạng thái
                </TableCell>
                <TableCell>
                  Ngày tạo
                </TableCell>
                <TableCell>
                  Người phê duyệt
                </TableCell>
                <TableCell>
                  Trạng thái duyệt
                </TableCell>
                {role.priority < 3 && <TableCell>
                  Thao tác
                </TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {items.slice(page * limit, page * limit + limit).map((baodo, index) => (
                <TableRow
                  hover
                  key={baodo.id}
                  selected={selectedBaodoIds.indexOf(baodo.id) !== -1}
                >
                  <TableCell>
                    {page * limit + index + 1}
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {baodo.so_dien_thoai}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {baodo.ten_goi_cuoc} - {numeral(baodo.gia_goi_cuoc).format('0,0')}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {baodo.kenh}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {moment(baodo.ngay_thanh_cong).format('DD-MM-YYYY')}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {baodo.username_nv} - {baodo.ten_nv}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {baodo.ly_do}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {role.priority < 3 && <><Select style={{ fontSize: "14px", width: "100%", marginBottom: '15px', color: (baodo.trang_thai == 'SUCCESS' ? 'green' : (baodo.trang_thai != 'FAILD' ? 'black' : 'red'))}} variant="outlined" value={baodo.trang_thai || 0} onChange={(e) => setTrangThai(baodo.id, e.target.value)}>
                        <MenuItem value="0">Chưa đặt</MenuItem>
                        <MenuItem value="SUCCESS">Thành công</MenuItem>
                        <MenuItem value="FAILD">Thất bại</MenuItem>
                      </Select>
                      </>}
                      {role.priority >= 3 && <span style={{color: (baodo.trang_thai == 'SUCCESS' ? 'green' : (baodo.trang_thai != 'FAILD' ? 'black' : 'red'))}}>{baodo.trang_thai == 'SUCCESS' ? 'Thành công' : (baodo.trang_thai != 'FAILD' ? 'Chưa đặt' : 'Thất bại')}</span>}
                      <br></br>Người đặt trạng thái: <span style={{fontWeight: 'bold', color: baodo.nguoi_dat_trang_thai != '__AUTO' ? 'blue' : 'gray'}}>{baodo.nguoi_dat_trang_thai}</span>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {moment(baodo.createdAt).format('DD-MM-YYYY HH:mm:ss')}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {baodo.nguoi_phe_duyet}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {baodo.trang_thai_duyet == '1' && <Typography
                      color="textPrimary"
                      variant="body1"
                      style={{color:'green'}}
                    >
                      ĐÃ DUYỆT
                    </Typography>}

                    {baodo.trang_thai_duyet == '2' && <Typography
                      color="textPrimary"
                      variant="body1"
                      style={{color:'red'}}
                    >
                      ĐÃ TỪ CHỐI
                    </Typography>}
                  </TableCell>
                  {role.priority < 3 && baodo.trang_thai_duyet == '0' && <TableCell>
                    <Button style={{color: 'green'}} onClick={() => approve(baodo.id, 1)}>
                      Duyệt
                    </Button>/
                    <Button style={{color: 'red'}} onClick={() => approve(baodo.id, 2)}>
                      Từ chối
                    </Button>
                  </TableCell>}
                  {role.priority < 5 && baodo.trang_thai_duyet != '0' && <TableCell></TableCell>}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={total}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
      />
      <CreateEditDialog open={open} handleClose={handleClose} type={"EDIT"} />
      <ConfirmDialog open={confirm} title={title} content={content || `Xác nhận xoá bản ghi `} highlight={selected.ten_goi_cuoc} handleClose={handleConfirmClose} handleConfirm={onConfirm} />
    </Card>
  );
};

export default BaodoLookupDetail;
