import { useState, useEffect } from 'react';

import styled from 'styled-components';

import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { LookupAction } from 'src/actions/lookup.action';

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';

import { AppAction } from '../../actions/app.action';

import React from 'react';

import moment from 'moment';

import io from 'socket.io-client';

import {
  DatePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LstracuuAction } from 'src/actions/lstracuu.action';
import MainSocket from 'src/components/MainSocket.js'

const CustomerMessage999 = (props) => {
  const dispatch = useDispatch()
  const { balance } = useSelector(state => state.lookup)
  const [values, setValues] = useState({
    phone: '',
    package_code: '',
    page: '',
    maxPage: '',
    huong_ket_qua: 'I',
    startDate: '',
    endDate: '',
  });
  const [data, setData] = useState([]);
  const { host, token, socket, socket_client_id } = MainSocket(useSelector(state => state));

  const StyledDatePickerWrapper = styled.div`
    width: 250px;
    margin-left: 15px;
    margin-top: 8px;
  `;

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleDateChange = (name, date) => {
    setValues({
      ...values,
      [name]: date
    });
  };

//   const onLookup = () => {
//     dispatch(LookupAction.lookupBalance(values.phone))
//   }

const getFromPage = (page) => {
    dispatch(AppAction.appLoading(true));
    setValues({
        ...values,
        page: page,
    });

    var phone = (values.phone).substring((values.phone).length - 9)
    var huong_ket_qua = values.huong_ket_qua ? values.huong_ket_qua : '';
    var startDate = values.startDate ? values.startDate.format("DD/MM/YYYY") : '';
    var endDate = values.endDate ? values.endDate.format("DD/MM/YYYY") : '';

    if (phone.trim() == '') {
      alert('Bạn phải nhập số điện thoại')
      dispatch(AppAction.appLoading(false));
      return;
    }

    console.log(phone, huong_ket_qua, startDate, endDate, page)

    if (typeof values.socketSend == 'undefined' || values.socketSend == null) {
      dispatch(LstracuuAction.create({ so_dien_thoai: phone, ten_tra_cuu: `Tra cứu tin nhắn 999`, noi_dung: `Tra cứu tin nhắn 999:\n{\nisdn: ${phone},\nhuong_ket_qua: ${huong_ket_qua},\nstartDate: ${startDate},\nendDate: ${endDate},\npage: ${page}\n}` }));

      values.socketSend = "traCuuTinNhan999";
      delete values.socketReceive;

      socket.emit("traCuuTinNhan999", {host, token, client_id: socket_client_id, data: {
        isdn: phone,
        huong_ket_qua: huong_ket_qua,
        startDate: startDate,
        endDate: endDate,
        page: page,
      }})
    }
}

const onLookup = () => {
    setValues({
        ...values,
        page: 1,
    });
    getFromPage(1)
  }

  socket.on("traKetQuaTraCuuTinNhan999", (data) => {
    if (typeof values.socketReceive == 'undefined' || values.socketReceive == null) {
      values.socketReceive = "traKetQuaTraCuuTinNhan999";
      delete values.socketSend;

      data = data.data;
      if (data && typeof data.NUMBER_PHONE !== 'undefined' && values && typeof values.phone !== 'undefined' && data.NUMBER_PHONE.slice(-9) == values.phone.slice(-9)) {
          console.log(data);
          setData(data);
          setValues({
              ...values,
              maxPage: typeof data.result !== 'undefined' && data.result && typeof data.result.PaginateNumber !== 'undefined' && data.result.PaginateNumber && data.result.PaginateNumber > 0 ? data.result.PaginateNumber : values.maxPage,
          });
          // if (data.result === null) onLookup();
          // else setValues({
          //     phone: '',
          //     package_code: '',
          //     page: '',
          // })
          dispatch(AppAction.appLoading(false));
      }
    }
  })

  useEffect(() => {
    dispatch(AppAction.appLoading(false));
}, [])

  return (
    <form {...props}>
      <Card>
        <CardHeader
          title="Trung tâm tin nhắn"
          subheader="Không có giới hạn"
        />
        <Divider />
        <CardContent>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              <TextField
                fullWidth
                style={{marginTop: '8px', width: '150px'}}
                label="Hướng kết quả"
                name="huong_ket_qua"
                onChange={handleChange}
                required
                select
                SelectProps={{ native: true }}
                value={values.phoneType}
                variant="outlined"
              >
                <option
                  key="I"
                  value="I"
                >
                  I - Input
                </option>

                <option
                  key="O"
                  value="O"
                >
                  O - Output
                </option>
              </TextField>
              <TextField
                fullWidth
                style={{marginLeft: '15px', width: '150px'}}
                label="Số điện thoại"
                margin="normal"
                name="phone"
                onChange={handleChange}
                required
                value={values.phone}
                variant="outlined"
              />
              <StyledDatePickerWrapper>
                <DatePicker
                  label="Thời gian bắt đầu"
                  format='DD/MM/YYYY'
                  value={values.startDate}
                  onChange={(newValue) => handleDateChange('startDate', newValue)}
                />
              </StyledDatePickerWrapper>
              <StyledDatePickerWrapper>
                <DatePicker
                  label="Thời gian kết thúc"
                  value={values.endDate}
                  format='DD/MM/YYYY'
                  onChange={(newValue) => handleDateChange('endDate', newValue)}
                />
              </StyledDatePickerWrapper>
              <Button
                color="primary"
                variant="contained"
                disabled={!values.phone}
                onClick={onLookup}
                style={{ height: 56, marginTop: 6, width: 200, marginLeft: 24 }}
              >
                Tra cứu
              </Button>
            </Box>
          </LocalizationProvider>
        </CardContent>
        <Divider />
        {data && typeof data.NUMBER_PHONE !== 'undefined' && <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            p: 2
          }}
        >
          <Table>
            <TableBody>

                <TableRow
                  hover
                >
                  <TableCell style={{fontWeight: 'bold'}}>
                    Thuê bao
                  </TableCell>
                  <TableCell style={{fontWeight: 'bold'}}>
                    Hướng
                  </TableCell>
                  <TableCell style={{fontWeight: 'bold'}}>
                    Đầu số
                  </TableCell>
                  <TableCell style={{fontWeight: 'bold'}}>
                    Mã dịch vụ
                  </TableCell>
                  <TableCell style={{fontWeight: 'bold'}}>
                    Cú pháp SMS
                  </TableCell>
                  <TableCell style={{fontWeight: 'bold'}}>
                    Nội dung SMS
                  </TableCell>
                  <TableCell style={{fontWeight: 'bold'}}>
                    Ngày thực hiện
                  </TableCell>
                </TableRow>

              {data && typeof data.result !== 'undefined' && data.result && typeof data.result.FinalDataList !== 'undefined' && data.result.FinalDataList && (data.result.FinalDataList).map((pkg) => (
                <><TableRow
                  hover
                >
                  <TableCell>
                    {pkg.ISDN}
                  </TableCell>
                  <TableCell>
                    {pkg.ActionType}
                  </TableCell>
                  <TableCell>
                    {pkg.NumberService}
                  </TableCell>
                  <TableCell>
                    {pkg.SystemSource}
                  </TableCell>
                  <TableCell>
                    {pkg.SMSSyntax}
                  </TableCell>
                  <TableCell>
                    {pkg.SMSContent}
                  </TableCell>
                  <TableCell>
                    {pkg.ActionTime}
                  </TableCell>
                </TableRow></>
              ))}
                <TableRow
                    hover
                >
                    <TableCell colSpan={7}>
                        {values.maxPage && Array.from({ length: values.maxPage }).map((_, index) => (
                            <React.Fragment key={index}>
                            {values.page !== index + 1 && (
                                <Button onClick={() => getFromPage(index + 1)}>
                                {index + 1}
                                </Button>
                            )}
                            {values.page === index + 1 && (
                                <Button disabled="true" style={{color: 'black'}}>
                                {index + 1}
                                </Button>
                            )}
                            </React.Fragment>
                        ))}
                    </TableCell>
                </TableRow>
            </TableBody>
          </Table>
        </Box>}
      </Card>
    </form>
  );
};

export default CustomerMessage999;
