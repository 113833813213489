import { BlacklistService } from 'src/services/blacklist.service';
import { showToast } from 'src/utils/common';
import { NOTI_TYPES } from 'src/utils/constants';

import { AppAction } from './app.action';

export const BLACKLIST_NEW_CARE_SUCCESS = 'BLACKLIST:NEW_CARE_SUCCESS'
export const BLACKLIST_LIST_SUCCESS = 'BLACKLIST:FIND_ALL_SUCCESS'
export const BLACKLIST_LIST_FAILD = 'BLACKLIST:FIND_ALL_FAILD'
export const BLACKLIST_COUNT_ASSIGN_SUCCESS = 'BLACKLIST:COUNT_ASSIGN_SUCCESS'
export const BLACKLIST_COUNT_ASSIGN_FAILD = 'BLACKLIST:COUNT_ASSIGN_FAILD'

export const BLACKLIST_CREATE_SUCCESS = 'BLACKLIST:CREATE_SUCCESS'
export const BLACKLIST_CREATE_FAILD = 'BLACKLIST:CREATE_FAILD'

export const BLACKLIST_IMPORT_SUCCESS = 'BLACKLIST:IMPORT_SUCCESS'
export const BLACKLIST_SAVE_SUCCESS = 'BLACKLIST:SAVE_SUCCESS'
export const BLACKLIST_SAVE_FAILD = 'BLACKLIST:SAVE_SUCCESS'

export const BLACKLIST_DEL_SUCCESS = 'BLACKLIST:DEL_SUCCESS'
export const BLACKLIST_DEL_FAILD = 'BLACKLIST:DEL_FAILD'

export const BLACKLIST_ONE_SUCCESS = 'BLACKLIST:FIND_ONE_SUCCESS'
export const BLACKLIST_ONE_FAILD = 'BLACKLIST:FIND_ONE_FAILD'

export const BLACKLIST_HISTORY_LIST_SUCCESS = 'BLACKLIST:HISTORY_FIND_ALL_SUCCESS'
export const BLACKLIST_HISTORY_LIST_FAILD = 'BLACKLIST:HISTORY_FIND_ALL_FAILD'

export const BLACKLIST_UPDATE_FILTER = 'BLACKLIST:UPDATE_FILTER'

export const BlacklistAction = {
    list,
    findAssign,
    create,
    importData,
    saveData,
    update,
    del,
    assign,
    countAssign,
    history,
    findOne,
    approve,
    updateFilter,
    saveDataUpload,
}

function updateFilter(payload) {
    return { type: BLACKLIST_UPDATE_FILTER, data: payload }
}

function saveDataUpload(payload) {
    return (dispatch) => {
        dispatch(AppAction.appLoading(true));
        let count = 0
        for (let i = 0; i < payload.length; i += 200) {
            // eslint-disable-next-line no-loop-func
            BlacklistService.save(payload.slice(i, i + 200)).then((rs) => {
                count += parseInt(rs.data.saved, 10)
                if (count === payload.length) {
                    dispatch(success(rs));
                    dispatch(AppAction.appLoading(false));
                }
            }).catch((error) => {
                dispatch(failure(error));
                dispatch(AppAction.appLoading(false));
            })
        }
    };

    function success(data) { return { type: BLACKLIST_SAVE_SUCCESS, data } }
    function failure(error) { return { type: BLACKLIST_SAVE_FAILD, error } }
}

function approve(id, payload) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            BlacklistService.approve(id, payload)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(BlacklistAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: BLACKLIST_CREATE_SUCCESS, data } }
    function failure(error) { return { type: BLACKLIST_CREATE_FAILD, error } }
}

function countAssign() {
    return (dispatch) => {
        dispatch(AppAction.appLoading(true));
        BlacklistService.countAssign().then((rs) => {
            dispatch(success(rs.data.total));
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: BLACKLIST_COUNT_ASSIGN_SUCCESS, data } }
    function failure(error) { return { type: BLACKLIST_COUNT_ASSIGN_FAILD, error } }
}

function findAssign(page = 0, count = 20) {
    return (dispatch, getState) => {
        const { blacklist } = getState()
        dispatch(AppAction.appLoading(true));
        BlacklistService.findAssign(page, count).then((rs) => {
            if (page === 0) {
                dispatch(success({
                    items: rs.data.values,
                    total: rs.data.count
                }));
            } else {
                dispatch(success({
                    items: [...blacklist.items, ...rs.data.values],
                    total: rs.data.count
                }));
            }
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: BLACKLIST_LIST_SUCCESS, data } }
    function failure(error) { return { type: BLACKLIST_LIST_FAILD, error } }
}

function list() {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        const { blacklist } = getState();
        const page = blacklist && typeof blacklist.filter !== 'undefined' && blacklist.filter && typeof blacklist.filter.page !== 'undefined' ? blacklist.filter.page : 0;
        const limit = blacklist && typeof blacklist.filter !== 'undefined' && blacklist.filter && typeof blacklist.filter.limit !== 'undefined' ? blacklist.filter.limit : 10;
        BlacklistService.findAll(page, limit, blacklist.filter).then((rs) => {
            if (page === 0) {
                dispatch(success({
                    items: rs.data.values,
                    total: rs.data.count
                }));
            } else {
                const oldItems = blacklist.items;
                const rsDataValues = rs.data.values;

                var uniqueItems = oldItems;
                for (var i = limit * page; i < limit * page + limit; i++) {
                    if (typeof rsDataValues[i - limit * page] !== 'undefined') {
                        uniqueItems[i] = rsDataValues[i - limit * page];
                    } else if (typeof uniqueItems[i] !== 'undefined') {
                        delete uniqueItems[i];
                    }
                }
                
                dispatch(success({
                    items: uniqueItems,
                    total: rs.data.count
                }));
            }
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: BLACKLIST_LIST_SUCCESS, data } }
    function failure(error) { return { type: BLACKLIST_LIST_FAILD, error } }
}

function importData(payload) {
    return { type: BLACKLIST_IMPORT_SUCCESS, data: payload }
}

function saveData(payload) {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        let count = 0
        for (let i = 0; i < payload.length; i += 200) {
            // eslint-disable-next-line no-loop-func
            BlacklistService.save(payload.slice(i, i + 200)).then((rs) => {
                count += parseInt(rs.data.saved, 10)
                if (count === payload.length) {
                    dispatch(success(rs));
                    dispatch(BlacklistAction.list())
                    dispatch(AppAction.appLoading(false));
                }
            }).catch((error) => {
                dispatch(failure(error));
                dispatch(AppAction.appLoading(false));
            })
        }
    };

    function success(data) { return { type: BLACKLIST_SAVE_SUCCESS, data } }
    function failure(error) { return { type: BLACKLIST_SAVE_FAILD, error } }
}

function create(payload) {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        BlacklistService.create(payload).then((rs) => {
            dispatch(success(rs));
            dispatch(BlacklistAction.list())
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: BLACKLIST_CREATE_SUCCESS, data } }
    function failure(error) { return { type: BLACKLIST_CREATE_FAILD, error } }
}

function update(id, payload) {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        BlacklistService.update(id, payload).then((rs) => {
            dispatch(success(rs));
            dispatch(BlacklistAction.list())
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: BLACKLIST_CREATE_SUCCESS, data } }
    function failure(error) { return { type: BLACKLIST_CREATE_FAILD, error } }
}

function del(id) {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        BlacklistService.delete(id).then((rs) => {
            dispatch(success(rs));
            dispatch(BlacklistAction.list())
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: BLACKLIST_DEL_SUCCESS, data } }
    function failure(error) { return { type: BLACKLIST_DEL_FAILD, error } }
}

function findOne(id) {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        BlacklistService.find(id).then((rs) => {
            dispatch(success(rs.data));
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: BLACKLIST_ONE_SUCCESS, data } }
    function failure(error) { return { type: BLACKLIST_ONE_FAILD, error } }
}

function assign(payload) {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        BlacklistService.assign(payload).then((rs) => {
            showToast('success', "Phân chia dữ liệu thành công.")
            dispatch(BlacklistAction.list())
        }).catch((error) => {
            showToast('error', "Phân chia dữ liệu thất bại.\nVui lòng thử lại lần sau.")
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
}


function history(payload) {
    return (dispatch, getState) => {
        const { blacklist } = getState()
        dispatch(AppAction.appLoading(true));
        BlacklistService.history(payload).then((rs) => {
            if (payload.page === 0) {
                dispatch(success({
                    items: rs.data.values,
                    total: rs.data.count
                }));
            } else {
                dispatch(success({
                    items: [...blacklist.items, ...rs.data.values],
                    total: rs.data.count
                }));
            }
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: BLACKLIST_HISTORY_LIST_SUCCESS, data } }
    function failure(error) { return { type: BLACKLIST_HISTORY_LIST_FAILD, error } }
}