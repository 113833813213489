import {
    useEffect,
    useState,
} from 'react';

import {
    useDispatch,
    useSelector,
} from 'react-redux';
import { PermissionAction } from 'src/actions/permission.acction';
import { GiagoimfyAction } from 'src/actions/giagoimfy.action';

import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    TextField,
    Typography,
    Grid,
} from '@material-ui/core';

const CreateEditDialog = (props) => {
    const dispatch = useDispatch()
    const [selectedPermissionIds, setSelectedPermissionIds] = useState([]);
    const { items } = useSelector(state => state.permission)
    const { selected } = useSelector(state => state.giagoimfy)
    const [ten_goi_cuoc, setTenGoiCuoc] = useState("")
    const [phi_thu_hoi, setPhiThuHoi] = useState("0")
    const [gia_t1, setGiaT1] = useState("0")
    const [gia_t2, setGiaT2] = useState("0")
    const [gia_t3, setGiaT3] = useState("0")

    useEffect(() => {
        if (items.length > 0) {
            return;
        }
        dispatch(PermissionAction.list(0, 100))
    }, [])

    useEffect(() => {
        if (props.type === 'EDIT') {
            if (selected) {
                setTenGoiCuoc(selected.ten_goi_cuoc)
                setPhiThuHoi(selected.phi_thu_hoi)
                setGiaT1(selected.gia_t1)
                setGiaT2(selected.gia_t2)
                setGiaT3(selected.gia_t3)
            }
        }
    }, [selected])

    const onPressPositive = () => {
        if (ten_goi_cuoc === "") {
            return;
        }
        if (phi_thu_hoi === "") {
            return;
        }
        if (gia_t1 === "") {
            return;
        }
        if (gia_t2 === "") {
            return;
        }
        if (gia_t3 === "") {
            return;
        }
        if (props.type === 'EDIT') {
            dispatch(GiagoimfyAction.update(selected.id, { ten_goi_cuoc, phi_thu_hoi, gia_t1, gia_t2, gia_t3 }))
        } else {
            dispatch(GiagoimfyAction.create({ ten_goi_cuoc, phi_thu_hoi, gia_t1, gia_t2, gia_t3 }))
        }
        resetState();
        props.handleClose();
    }

    const resetState = () => {
        setTenGoiCuoc("")
        setPhiThuHoi("0")
        setGiaT1("0")
        setGiaT2("0")
        setGiaT3("0")
    }

    const handleSelectOne = (event, id) => {
        const selectedIndex = selectedPermissionIds.indexOf(id);
        let newSelectedCustomerIds = [];
        if (selectedIndex === -1) {
            newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedPermissionIds, id);
        } else if (selectedIndex === 0) {
            newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedPermissionIds.slice(1));
        } else if (selectedIndex === selectedPermissionIds.length - 1) {
            newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedPermissionIds.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelectedCustomerIds = newSelectedCustomerIds.concat(
                selectedPermissionIds.slice(0, selectedIndex),
                selectedPermissionIds.slice(selectedIndex + 1)
            );
        }
        setSelectedPermissionIds(newSelectedCustomerIds);
    };

    return (
        <Dialog open={props.open} onClose={props.handleClose}>
            <DialogTitle>{props.type === 'EDIT' ? "Cập nhật bản ghi" : "Tạo bản ghi mới"}</DialogTitle>
            <DialogContent style={{ width: 600, paddingTop: 20 }}>
                <Grid
                    container
                    spacing={3}
                >
                    <Grid
                        item
                        md={12}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            label="Tên gói cước"
                            name="ten_goi_cuoc"
                            onChange={(e) => setTenGoiCuoc(e.target.value)}
                            required
                            value={ten_goi_cuoc}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid
                        item
                        md={12}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            label="Phí thu hồi"
                            name="phi_thu_hoi"
                            onChange={(e) => setPhiThuHoi(e.target.value)}
                            value={phi_thu_hoi}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid
                        item
                        md={12}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            label="Giá T1"
                            name="gia_t1"
                            onChange={(e) => setGiaT1(e.target.value)}
                            value={gia_t1}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid
                        item
                        md={12}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            label="Giá T2"
                            name="gia_t2"
                            onChange={(e) => setGiaT2(e.target.value)}
                            value={gia_t2}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid
                        item
                        md={12}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            label="Giá T3"
                            name="gia_t3"
                            onChange={(e) => setGiaT3(e.target.value)}
                            value={gia_t3}
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose}>Huỷ</Button>
                <Button onClick={onPressPositive}>{props.type === 'EDIT' ? "Cập nhật" : "Tạo"}</Button>
            </DialogActions>
        </Dialog >
    );
}

export default CreateEditDialog;