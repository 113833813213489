import {
  useEffect,
  useState,
} from 'react';

import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { Customermessage9084Action } from 'src/actions/customermessage9084.action';
import {
  DEFAULT_ROW_PER_PAGE,
  ROW_PER_PAGE_OPTIONS,
} from 'src/utils/constants';

import {
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';

const Customermessage9084LookupDetail = ({ ...rest }) => {
  const dispatch = useDispatch()
  const { items, total, filter } = useSelector(state => state.customermessage9084);
  const { info, permissions, role } = useSelector(state => state.profile)
  const [selectedCustomermessage9084Ids, setSelectedCustomermessage9084Ids] = useState([]);
  const [limit, setLimit] = useState(filter.limit || DEFAULT_ROW_PER_PAGE);
  const [page, setPage] = useState(filter.page || 0);


  useEffect(() => {
    const payload = {
      ...filter,
      page: page,
      limit: limit,
    };

    dispatch(Customermessage9084Action.updateFilter(payload));

    dispatch(Customermessage9084Action.list());
  }, [page, limit])

  const handleSelectAll = (event) => {
    let newSelectedCustomermessage9084Ids;

    if (event.target.checked) {
      newSelectedCustomermessage9084Ids = items.map((customermessage9084) => customermessage9084.id);
    } else {
      newSelectedCustomermessage9084Ids = [];
    }

    setSelectedCustomermessage9084Ids(newSelectedCustomermessage9084Ids);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedCustomermessage9084Ids.indexOf(id);
    let newSelectedCustomermessage9084Ids = [];

    if (selectedIndex === -1) {
      newSelectedCustomermessage9084Ids = newSelectedCustomermessage9084Ids.concat(selectedCustomermessage9084Ids, id);
    } else if (selectedIndex === 0) {
      newSelectedCustomermessage9084Ids = newSelectedCustomermessage9084Ids.concat(selectedCustomermessage9084Ids.slice(1));
    } else if (selectedIndex === selectedCustomermessage9084Ids.length - 1) {
      newSelectedCustomermessage9084Ids = newSelectedCustomermessage9084Ids.concat(selectedCustomermessage9084Ids.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedCustomermessage9084Ids = newSelectedCustomermessage9084Ids.concat(
        selectedCustomermessage9084Ids.slice(0, selectedIndex),
        selectedCustomermessage9084Ids.slice(selectedIndex + 1)
      );
    }

    setSelectedCustomermessage9084Ids(newSelectedCustomermessage9084Ids);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Card {...rest} style={{ width: "100%"}}>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  #
                </TableCell>
                <TableCell>
                  Kênh
                </TableCell>
                <TableCell>
                  Số thuê bao
                </TableCell>
                <TableCell>
                  Tên gói cước
                </TableCell>
                <TableCell>
                  Giá bán
                </TableCell>
                <TableCell>
                  Thời gian
                </TableCell>
                <TableCell>
                  Trạng thái
                </TableCell>
                <TableCell>
                  Nhân viên
                </TableCell>
                <TableCell>
                  Nội dung
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.slice(page * limit, (page + 1) * limit).map((customermessage9084, index) => (
                <TableRow
                  hover
                  key={customermessage9084.id}
                  selected={selectedCustomermessage9084Ids.indexOf(customermessage9084.id) !== -1}
                >
                  <TableCell>
                    {page * limit + index + 1}
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {customermessage9084.SubAgent}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {customermessage9084.NumberPhone}
                  </TableCell>
                  <TableCell>
                    {customermessage9084.PackageCode}
                  </TableCell>
                  <TableCell>
                    {customermessage9084.Price}
                  </TableCell>
                  <TableCell>
                    {moment(customermessage9084.Time, 'DD/MM/YYYY HH:mm').format('DD-MM-YYYY HH:mm:ss')}
                  </TableCell>
                  <TableCell>
                    {customermessage9084.Status == 1 ? 'Thành công' : 'Thất bại'}
                  </TableCell>
                  <TableCell>
                    {role.priority < 3 || role.priority > 2 && customermessage9084.username_nv != 'ADMINMB0000' ? ((customermessage9084.username_nv ? customermessage9084.username_nv : '') + (customermessage9084.ten_nv ? (' - ' + customermessage9084.ten_nv) : '')) : ''}
                  </TableCell>
                  <TableCell>
                    {customermessage9084.Result}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={total}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
      />
    </Card>
  );
};

export default Customermessage9084LookupDetail;
