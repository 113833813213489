import { Helmet } from 'react-helmet';
import ChamsocDetail from 'src/components/chamsoc-detail/ChamsocDetail';
import {
  Box,
  Container,
} from '@material-ui/core';

const Chamsoc = () => (
  <>
    <Helmet>
      <title>Chăm sóc</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container maxWidth="lg">
        <Box sx={{ pt: 3 }}>
          <ChamsocDetail />
        </Box>
      </Container>
    </Box>
  </>
);

export default Chamsoc;
