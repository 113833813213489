import {
  useEffect,
  useState,
} from 'react';
import {
  Edit3 as Edit3Icon,
  Lock,
  RefreshCcw,
  Trash2 as Trash2Icon,
  Unlock,
} from 'react-feather';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { KhrAction } from 'src/actions/khr.action';
import {
  CONFIRM_TYPES,
  DEFAULT_ROW_PER_PAGE,
  ROW_PER_PAGE_OPTIONS,
} from 'src/utils/constants';
import { getKhrStatus } from 'src/utils/utils';
import ConfirmDialog from '../ConfirmDialog';
import {
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  IconButton,
  Button,
} from '@material-ui/core';
import CreateEditDialog from './CreateEditDialog';

const KhrLookupDetail = ({ ...rest }) => {
  const dispatch = useDispatch()
  const [selectedKhrIds, setSelectedKhrIds] = useState([]);
  const [limit, setLimit] = useState(DEFAULT_ROW_PER_PAGE);
  const [page, setPage] = useState(0);
  const { role } = useSelector(state => state.profile);
  const { items, total, filter } = useSelector(state => state.khr);
  const [confirm, setConfirm] = useState(false);
  const [confirmType, setConfirmType] = useState("");
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [selected, setSelected] = useState({});
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const payload = {
      ...filter,
      limit: limit,
      page: page,
    };
    dispatch(KhrAction.updateFilter(payload));
    dispatch(KhrAction.list(page, limit))
  }, [page, limit])

  const handleSelectAll = (event) => {
    let newSelectedKhrIds;

    if (event.target.checked) {
      newSelectedKhrIds = items.map((khr) => khr.id);
    } else {
      newSelectedKhrIds = [];
    }

    setSelectedKhrIds(newSelectedKhrIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedKhrIds.indexOf(id);
    let newSelectedKhrIds = [];

    if (selectedIndex === -1) {
      newSelectedKhrIds = newSelectedKhrIds.concat(selectedKhrIds, id);
    } else if (selectedIndex === 0) {
      newSelectedKhrIds = newSelectedKhrIds.concat(selectedKhrIds.slice(1));
    } else if (selectedIndex === selectedKhrIds.length - 1) {
      newSelectedKhrIds = newSelectedKhrIds.concat(selectedKhrIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedKhrIds = newSelectedKhrIds.concat(
        selectedKhrIds.slice(0, selectedIndex),
        selectedKhrIds.slice(selectedIndex + 1)
      );
    }

    setSelectedKhrIds(newSelectedKhrIds);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleConfirmOpen = (type, khr) => {
    setSelected(khr);
    setConfirmType(type);
    if (confirmType === CONFIRM_TYPES.DELETE) {
      setTitle("Xoá bản ghi")
      setContent(`Xác nhận xoá bản ghi `)
    }
    setConfirm(true);
  };

  const onConfirm = () => {
    setConfirm(false);
    if (confirmType === CONFIRM_TYPES.DELETE) {
      dispatch(KhrAction.del(selected.id))
      return;
    }
  }

  const handleConfirmClose = () => {
    setConfirm(false);
  };

  const onSelected = (id) => {
    dispatch(KhrAction.findOne(id));
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const approve = async (id, trang_thai) => {
    const question = trang_thai == 1 ? "Xác nhận duyệt đơn này?" : "Xác nhận từ chối đơn này?" ;
    if (window.confirm(question)) {
      try {
        const response = await dispatch(KhrAction.approve(id, { trang_thai }));
        if (typeof response.channel !== 'undefined') alert("[Thông báo từ '"+response.channel+"']\n"+response.message+(response.status != "Unknown" ? (" ("+response.status+")") : ""));
        else if (typeof response.code !== 'undefined' && typeof response.msg !== 'undefined') alert("[Lỗi]\n"+response.msg)
      } catch (error) {
        if (typeof error.code !== 'undefined' && typeof error.msg !== 'undefined') alert("[Lỗi]\n"+error.msg)
      }
    } else {
      
    }
  };

  return (
    <Card {...rest} style={{ width: "100%"}}>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  #
                </TableCell>
                <TableCell>
                  Số điện thoại
                </TableCell>
                <TableCell>
                  Ngày thành công
                </TableCell>
                <TableCell>
                  Nhân viên
                </TableCell>
                <TableCell>
                  Ngày tạo
                </TableCell>
                <TableCell>
                  Người phê duyệt
                </TableCell>
                <TableCell>
                  Trạng thái
                </TableCell>
                {role.priority < 5 && <TableCell>
                  Thao tác
                </TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {items.slice(page * limit, page * limit + limit).map((khr, index) => (
                <TableRow
                  hover
                  key={khr.id}
                  selected={selectedKhrIds.indexOf(khr.id) !== -1}
                >
                  <TableCell>
                    {page * limit + index + 1}
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {khr.so_dien_thoai}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {moment(khr.ngay_thanh_cong).format('DD-MM-YYYY')}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {khr.username_nv} - {khr.ten_nv}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {moment(khr.createdAt).format('DD-MM-YYYY HH:mm:ss')}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {khr.nguoi_phe_duyet}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {khr.trang_thai == '1' && <Typography
                      color="textPrimary"
                      variant="body1"
                      style={{color:'green'}}
                    >
                      ĐÃ DUYỆT
                    </Typography>}

                    {khr.trang_thai == '2' && <Typography
                      color="textPrimary"
                      variant="body1"
                      style={{color:'red'}}
                    >
                      ĐÃ TỪ CHỐI
                    </Typography>}
                  </TableCell>
                  {role.priority < 5 && khr.trang_thai == '0' && <TableCell>
                    <Button style={{color: 'green'}} onClick={() => approve(khr.id, 1)}>
                      Duyệt
                    </Button>/
                    <Button style={{color: 'red'}} onClick={() => approve(khr.id, 2)}>
                      Từ chối
                    </Button>
                  </TableCell>}
                  {role.priority < 5 && khr.trang_thai != '0' && <TableCell></TableCell>}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={total}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
      />
      <CreateEditDialog open={open} handleClose={handleClose} type={"EDIT"} />
      <ConfirmDialog open={confirm} title={title} content={content || `Xác nhận xoá bản ghi `} highlight={selected.ten_goi_cuoc} handleClose={handleConfirmClose} handleConfirm={onConfirm} />
    </Card>
  );
};

export default KhrLookupDetail;
