import {
  useEffect,
  useState,
} from 'react';

import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { LstracuuAction } from 'src/actions/lstracuu.action';
import {
  DEFAULT_ROW_PER_PAGE,
  ROW_PER_PAGE_OPTIONS,
} from 'src/utils/constants';

import {
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';

import numeral from 'numeral';

const LstracuuLookupDetail = ({ ...rest }) => {
  const dispatch = useDispatch()
  const { items, total, filter, countByName } = useSelector(state => state.lstracuu);
  const { role } = useSelector(state => state.profile);
  const [selectedLstracuuIds, setSelectedLstracuuIds] = useState([]);
  const [limit, setLimit] = useState(filter.limit || DEFAULT_ROW_PER_PAGE);
  const [page, setPage] = useState(filter.page || 0);


  useEffect(() => {
    const payload = {
      ...filter,
      page: page,
      limit: limit,
    };

    dispatch(LstracuuAction.updateFilter(payload));

    dispatch(LstracuuAction.list());
  }, [page, limit])

  const handleSelectAll = (event) => {
    let newSelectedLstracuuIds;

    if (event.target.checked) {
      newSelectedLstracuuIds = items.map((lstracuu) => lstracuu.id);
    } else {
      newSelectedLstracuuIds = [];
    }

    setSelectedLstracuuIds(newSelectedLstracuuIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedLstracuuIds.indexOf(id);
    let newSelectedLstracuuIds = [];

    if (selectedIndex === -1) {
      newSelectedLstracuuIds = newSelectedLstracuuIds.concat(selectedLstracuuIds, id);
    } else if (selectedIndex === 0) {
      newSelectedLstracuuIds = newSelectedLstracuuIds.concat(selectedLstracuuIds.slice(1));
    } else if (selectedIndex === selectedLstracuuIds.length - 1) {
      newSelectedLstracuuIds = newSelectedLstracuuIds.concat(selectedLstracuuIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedLstracuuIds = newSelectedLstracuuIds.concat(
        selectedLstracuuIds.slice(0, selectedIndex),
        selectedLstracuuIds.slice(selectedIndex + 1)
      );
    }

    setSelectedLstracuuIds(newSelectedLstracuuIds);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Card {...rest} style={{ width: "100%"}}>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          {countByName && <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Tên tra cứu
                </TableCell>
                <TableCell>
                  Lượt tra cứu
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {countByName.slice(page * limit, (page + 1) * limit).map((thongKe, index) => (
                <TableRow
                  hover
                  key={thongKe.ten_tra_cuu}
                >
                  <TableCell>
                    {thongKe.ten_tra_cuu}
                  </TableCell>
                  <TableCell>
                    {numeral(thongKe.count).format('0,0')}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>}
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  #
                </TableCell>
                <TableCell>
                  Username NV - Tên NV
                </TableCell>
                <TableCell>
                  Nội dung tra cứu
                </TableCell>
                <TableCell>
                  Thời điểm tra cứu
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.slice(page * limit, (page + 1) * limit).map((lstracuu, index) => (
                <TableRow
                  hover
                  key={lstracuu.id}
                  selected={selectedLstracuuIds.indexOf(lstracuu.id) !== -1}
                >
                  <TableCell>
                    {page * limit + index + 1}
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {lstracuu.username} - {lstracuu.full_name}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {(lstracuu.noi_dung.split('\n')).map((line, index) => (
                      <div key={index}>{line}</div>
                    ))}
                  </TableCell>
                  <TableCell>
                    {moment(lstracuu.createdAt).format('DD-MM-YYYY HH:mm:ss')}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={total}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
      />
    </Card>
  );
};

export default LstracuuLookupDetail;
