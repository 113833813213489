import { Helmet } from 'react-helmet';
import BlacklistDetail
  from 'src/components/blacklist-detail/BlacklistDetail';

import {
  Box,
  Container,
} from '@material-ui/core';

import BlacklistListToolbar from '../components/blacklist-detail/BlacklistToolbar';

const Blacklist = () => (
  <>
    <Helmet>
      <title>Blacklist</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container style={{maxWidth: "100%"}}>
        <BlacklistListToolbar />
        <Box sx={{ pt: 3 }}>
          <BlacklistDetail />
        </Box>
      </Container>
    </Box>
  </>
);

export default Blacklist;
