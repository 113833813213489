import { Helmet } from 'react-helmet';
import MoigoihomsauDetail
  from 'src/components/moigoihomsau-detail/MoigoihomsauDetail';

import {
  Box,
  Container,
} from '@material-ui/core';

import MoigoihomsauListToolbar from '../components/moigoihomsau-detail/MoigoihomsauToolbar';

const Moigoihomsau = () => (
  <>
    <Helmet>
      <title>Mời gói hôm sau</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container style={{maxWidth: "100%"}}>
        <MoigoihomsauListToolbar />
        <Box sx={{ pt: 3 }}>
          <MoigoihomsauDetail />
        </Box>
      </Container>
    </Box>
  </>
);

export default Moigoihomsau;
