import { Helmet } from 'react-helmet';
import { useEffect, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';

import { emitter } from 'src/utils/emitter';

import {
  Box,
  Tab,
  Tabs,
  AppBar,
  Container,
  makeStyles,
  useTheme,
  Typography
} from '@material-ui/core';

import { Cancel } from '@material-ui/icons';

import KeepAlive from 'react-activation'

import Dashboard from './Dashboard';
import CustomerCareList from './CustomerCareList';
import Khr from './Khr';
import Mfy from './Mfy';
import Blacklist from './Blacklist';
import Baodo from './Baodo';
import CustomerCareHistoryList from './CustomerCareHistoryList';
import TilebatsoList from './TilebatsoList';
import CustomerLookup from './CustomerLookup';
import CustomerMessage999 from './CustomerMessage999';
import CustomerMessage9084 from './CustomerMessage9084';
import BankLookup from './BankLookup';
import Requestrefund from './Requestrefund';
import Invitation from './Invitation';
import TinhluongList from './TinhluongList';
import ReportbranchrevenueList from './ReportbranchrevenueList';
import ReportstaffrevenueList from './ReportstaffrevenueList';
import Report90849332List from './Report90849332List';
import Requestrefundreport from './Requestrefundreport';
import DsdttinhluongList from './DsdttinhluongList';
import AccountList from './AccountList';
import LstracuuLookup from './LstracuuLookup';
import RoleList from './RoleList';
import Giahoantien from './Giahoantien';
import Giagoimfy from './Giagoimfy';
import Systemaccount from './Systemaccount';
import Quetdata from './Quetdata';
import Quetdatakhonggoi from './Quetdatakhonggoi';
import Quetdtct from './Quetdtct';
import Uploadchiadata from './Uploadchiadata';
import Phanquyendata from './Phanquyendata';
import Chiadata from './Chiadata';

const componentList = [
  {
    name: "Trang chủ",
    rawName: "Dashboard",
    component: Dashboard
  },
  {
    name: "Khách hàng mới",
    rawName: "CustomerCareList",
    component: CustomerCareList
  },
  {
    name: "Khách hàng riêng",
    rawName: "Khr",
    component: Khr
  },
  {
    name: "Mfy",
    rawName: "Mfy",
    component: Mfy
  },
  {
    name: "Blacklist",
    rawName: "Blacklist",
    component: Blacklist
  },
  {
    name: "Báo đỏ",
    rawName: "Baodo",
    component: Baodo
  },
  {
    name: "Nhật ký CSKH",
    rawName: "CustomerCareHistoryList",
    component: CustomerCareHistoryList
  },
  {
    name: "Tỉ lệ bắt số",
    rawName: "TilebatsoList",
    component: TilebatsoList
  },
  {
    name: "Tra cứu thuê bao",
    rawName: "CustomerLookup",
    component: CustomerLookup
  },
  {
    name: "Trung tâm tin nhắn",
    rawName: "CustomerMessage999",
    component: CustomerMessage999
  },
  {
    name: "Kết quả đăng ký",
    rawName: "CustomerMessage9084",
    component: CustomerMessage9084
  },
  {
    name: "Tra cứu ngân hàng",
    rawName: "BankLookup",
    component: BankLookup
  },
  {
    name: "Yêu cầu hoàn tiền",
    rawName: "Requestrefund",
    component: Requestrefund
  },
  {
    name: "Mời gói cước",
    rawName: "Invitation",
    component: Invitation
  },
  {
    name: "Tính lương",
    rawName: "TinhluongList",
    component: TinhluongList
  },
  {
    name: "Báo cáo doanh thu theo chi nhánh",
    rawName: "ReportbranchrevenueList",
    component: ReportbranchrevenueList
  },
  {
    name: "Báo cáo doanh thu theo nhân viên",
    rawName: "ReportstaffrevenueList",
    component: ReportstaffrevenueList
  },
  {
    name: "Báo cáo doanh thu chi tiết",
    rawName: "Report90849332List",
    component: Report90849332List
  },
  {
    name: "Báo cáo yêu cầu hoàn tiền",
    rawName: "Requestrefundreport",
    component: Requestrefundreport
  },
  {
    name: "Đối soát doanh thu tính lương",
    rawName: "DsdttinhluongList",
    component: DsdttinhluongList
  },
  {
    name: "Quản lý nhân viên",
    rawName: "AccountList",
    component: AccountList
  },
  {
    name: "Lịch sử tra cứu",
    rawName: "LstracuuLookup",
    component: LstracuuLookup
  },
  {
    name: "Phân quyền theo vai trò",
    rawName: "RoleList",
    component: RoleList
  },
  {
    name: "Giá hoàn tiền",
    rawName: "Giahoantien",
    component: Giahoantien
  },
  {
    name: "Giá gói mfy",
    rawName: "Giagoimfy",
    component: Giagoimfy
  },
  {
    name: "Tài khoản hệ thống",
    rawName: "Systemaccount",
    component: Systemaccount
  },
  {
    name: "Quét data",
    rawName: "Quetdata",
    component: Quetdata
  },
  {
    name: "Quét data không gói",
    rawName: "Quetdatakhonggoi",
    component: Quetdatakhonggoi
  },
  {
    name: "Quét đối tượng chương trình",
    rawName: "Quetdtct",
    component: Quetdtct
  },
  {
    name: "Upload chia data",
    rawName: "Uploadchiadata",
    component: Uploadchiadata
  },
  {
    name: "Phân quyền data",
    rawName: "Phanquyendata",
    component: Phanquyendata
  },
  {
    name: "Chia data",
    rawName: "Chiadata",
    component: Chiadata
  },
]

var tabListProxy = []

const _HomePage = () => {
  const [tabList, setTabList] = useState([])
  const [curActiveTab, setActiveTab] = useState("")

  useEffect(() => {
    if (localStorage.getItem("tabList")) {
      const sampleTabs = []
      const archiveTabs = JSON.parse(localStorage.getItem("tabList"))

      for (const tab of archiveTabs) {
        const tData = componentList.find(c => c.rawName == tab.rawName)

        if (tData) {
          sampleTabs.push(tData)
        }
      }

      tabListProxy = sampleTabs
      setTabList(sampleTabs)

      setTimeout(() => {
        if (localStorage.getItem("lastActiveTab")) {
          _setActiveTab(localStorage.getItem("lastActiveTab"))
        }
      })
    }

    emitter.on("add_tab", (component) => {
      const tData = tabListProxy.find(c => c.rawName == component)

      if (tData) {
        setActiveTab(tData.rawName)

        return // block duplicate
      }

      const cData = componentList.find(c => c.rawName == component)

      AddNewTab(cData)
    })
  }, [])

  const AddNewTab = tab => {
    let cloneArray = [...tabListProxy]
    cloneArray.push(tab)

    tabListProxy = cloneArray
    localStorage.setItem("tabList", JSON.stringify(tabListProxy))

    setTabList(cloneArray)
    _setActiveTab(tab.rawName)
  }

  const clickTab = tab => {
    _setActiveTab(tab.rawName)
  }

  const closeTab = tab => {
    let cloneArray = [...tabListProxy]
    cloneArray = cloneArray.filter(t => t.rawName != tab.rawName)

    tabListProxy = [...cloneArray]

    if (tabListProxy.length > 0) {
      _setActiveTab(tabListProxy[tabListProxy.length - 1].rawName)
    }

    setTabList(tabListProxy)
    localStorage.setItem("tabList", JSON.stringify(tabListProxy))
  }

  const _setActiveTab = tabName => {
    setActiveTab(tabName)
    localStorage.setItem("lastActiveTab", tabName)
  }

  const renderTabName = tab => {
    return <span><span style={{ cursor: "pointer" }} onClick={() => clickTab(tab)}>{tab.name}</span> <Cancel onClick={() => closeTab(tab)} fontSize="inherit" style={{ color: "#e74c3c", cursor: "pointer" }} /></span>
  }

  return (
    <>
      <Helmet>
        <title>Trang chủ</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <AppBar position="static" color="default">
            <Tabs
              value={curActiveTab}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
            >
              {tabList.map((tab, index) => <Tab value={tab.rawName} key={index} label={renderTabName(tab)} />)}
            </Tabs>
          </AppBar>

          {tabList.map((tab, index) => {
            const { component: Component } = tab
            return curActiveTab == tab.rawName &&
              <KeepAlive>
                <div style={{ height: "80vh", overflow: "auto" }}>
                  <Component key={tab.rawName} />
                </div>
              </KeepAlive>
          })}
        </Container>
      </Box>
    </>
  )
};

export default _HomePage;
