import {
    useEffect,
    useState,
} from 'react';

import {
    useDispatch,
    useSelector,
} from 'react-redux';
import { PermissionAction } from 'src/actions/permission.acction';
import { RequestrefundreportAction } from 'src/actions/requestrefundreport.action';
import { SettingAction } from 'src/actions/setting.action';

import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    TextField,
    Typography,
    Grid,
    Select,
    MenuItem,
    InputLabel,
} from '@material-ui/core';

import { showToast } from 'src/utils/common';

const CreateEditDialog = (props) => {
    const dispatch = useDispatch()
    const [selectedPermissionIds, setSelectedPermissionIds] = useState([]);
    const { items } = useSelector(state => state.permission)
    const { selected } = useSelector(state => state.requestrefundreport)
    const [number_phone, setThueBao] = useState("")
    const [number_type, setLoaiThueBao] = useState("")
    const [package_code, setTenGoiCuoc] = useState("")
    const [refundreport_price, setSoTienCanNap] = useState("")
    const [is_transfer, setTrangThaiChuyenKhoan] = useState("")
    const [transfer_final_amount, setSoTienKHCanChuyen] = useState("")
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [ti_le_chiet_khau, setTiLeChietKhau] = useState("")

    useEffect(() => {
        async function fetchData() {
            const ti_le_chiet_khau_res = await dispatch(SettingAction.findByKey('ti_le_chiet_khau'))
            setTiLeChietKhau(ti_le_chiet_khau_res.data.value)
        }
        fetchData();
    }, [])

    useEffect(() => {
        if (items.length > 0) {
            return;
        }
        dispatch(PermissionAction.list(0, 100))
    }, [])

    const onPressPositive = async () => {
        if (number_phone === "") {
            alert('[Lỗi]\nCó trường bắt buộc chưa được nhập hoặc chưa được chọn')
            return;
        }
        if (number_type === "") {
            alert('[Lỗi]\nCó trường bắt buộc chưa được nhập hoặc chưa được chọn')
            return;
        }
        if (package_code === "") {
            alert('[Lỗi]\nCó trường bắt buộc chưa được nhập hoặc chưa được chọn')
            return;
        }
        if (refundreport_price === "") {
            alert('[Lỗi]\nCó trường bắt buộc chưa được nhập hoặc chưa được chọn')
            return;
        }
        // if (is_transfer === "") {
        //     alert('[Lỗi]\nCó trường bắt buộc chưa được nhập hoặc chưa được chọn')
        //     return;
        // }

        setIsButtonDisabled(true);
        
        try {
            const response = await dispatch(RequestrefundreportAction.createNapTienChietKhau({ number_phone, number_type, package_code, refundreport_price, is_transfer }));

            if (response) setIsButtonDisabled(false);

            if (typeof response.channel !== 'undefined') alert("[Thông báo từ '"+response.channel+"']\n"+response.message+(response.status != "Unknown" ? (" ("+response.status+")") : ""));
            else if (typeof response.code !== 'undefined' && typeof response.msg !== 'undefined') alert("[Lỗi]\n"+response.msg)
            resetState();
            props.handleClose();
        } catch (error) {
            setIsButtonDisabled(false);
            if (typeof error.code !== 'undefined' && typeof error.msg !== 'undefined') alert("[Lỗi]\n"+error.msg)
        }
    }

    const resetState = () => {
        setThueBao("")
        setLoaiThueBao("")
        setTenGoiCuoc("")
        setSoTienCanNap("")
        setTrangThaiChuyenKhoan("")
    }

    const handleSelectOne = (event, id) => {
        const selectedIndex = selectedPermissionIds.indexOf(id);
        let newSelectedCustomerIds = [];
        if (selectedIndex === -1) {
            newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedPermissionIds, id);
        } else if (selectedIndex === 0) {
            newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedPermissionIds.slice(1));
        } else if (selectedIndex === selectedPermissionIds.length - 1) {
            newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedPermissionIds.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelectedCustomerIds = newSelectedCustomerIds.concat(
                selectedPermissionIds.slice(0, selectedIndex),
                selectedPermissionIds.slice(selectedIndex + 1)
            );
        }
        setSelectedPermissionIds(newSelectedCustomerIds);
    };

    const copyToClipboard = (content) => {
        navigator.clipboard.writeText(content)
          .then(() => {
            console.log('String copied to clipboard:', content);
            showToast('success', `Đã copy: ${content}`)
          })
          .catch((error) => {
            console.error('Unable to copy string:', error);
            showToast('error', `Lỗi không thể copy: ${content}`)
          });
      }

    return (
        <Dialog open={props.open} onClose={props.handleClose}>
            <DialogTitle>Nạp tiền chiết khấu</DialogTitle>
            <DialogContent style={{ width: 600, paddingTop: 20 }}>
                <Grid
                    container
                    spacing={3}
                >
                    <Grid
                        item
                        md={6}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            label="Thuê bao"
                            name="number_phone"
                            onChange={(e) => setThueBao(e.target.value)}
                            value={number_phone}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid
                        item
                        md={6}
                        xs={12}
                    >
                        <Select fullWidth variant="outlined" name="number_type" value={number_type || -99} onChange={(e) => setLoaiThueBao(e.target.value)}>
                            <MenuItem value="-99" selected>Chọn loại thuê bao...</MenuItem>
                            <MenuItem value="1">Trả trước</MenuItem>
                            <MenuItem value="2">Trả sau</MenuItem>
                        </Select>
                    </Grid>
                    <Grid
                        item
                        md={6}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            label="Tên gói cước"
                            name="package_code"
                            onChange={(e) => setTenGoiCuoc(e.target.value)}
                            value={package_code}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid
                        item
                        md={6}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            label="Số tiền cần nạp"
                            name="refundreport_price"
                            onChange={(e) => {setSoTienCanNap(e.target.value); setSoTienKHCanChuyen(Math.round(e.target.value - (e.target.value / 100 * parseInt(ti_le_chiet_khau))))}}
                            value={refundreport_price}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid
                        item
                        md={6}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            label="Chiết khấu"
                            value={parseInt(ti_le_chiet_khau) + "%"}
                            variant="outlined"
                            aria-readonly
                        />
                    </Grid>
                    <Grid
                        item
                        md={6}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            label="Số tiền KH cần chuyển"
                            name="transfer_final_amount"
                            onChange={(e) => setSoTienKHCanChuyen(e.target.value)}
                            value={transfer_final_amount}
                            variant="outlined"
                            aria-readonly
                        />
                    </Grid>
                    <Grid
                        item
                        md={12}
                        xs={12}
                    >
                        Nội dung chuyển khoản:
                        <Button style={{padding: 0}} onClick={() => copyToClipboard('0' + number_phone.slice(-9) + ' ' + package_code.toUpperCase() + ' ' + transfer_final_amount.replace(/,/g, ''))}>
                            {'0' + number_phone.slice(-9) + ' ' + package_code.toUpperCase() + ' ' + transfer_final_amount.replace(/,/g, '')}
                        </Button>
                    </Grid>
                    {1 < 0 && <Grid
                        item
                        md={6}
                        xs={12}
                    >
                        <Select fullWidth variant="outlined" name="is_transfer" value={is_transfer || -99} onChange={(e) => setTrangThaiChuyenKhoan(e.target.value)}>
                            <MenuItem value="-99" selected>Chọn t.thái ch.khoản...</MenuItem>
                            <MenuItem value="1">Đã chuyển khoản</MenuItem>
                            <MenuItem value="0">Chưa chuyển khoản</MenuItem>
                        </Select>
                    </Grid>}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose}>Huỷ</Button>
                <Button onClick={onPressPositive} disabled={isButtonDisabled}>Gửi yêu cầu</Button>
            </DialogActions>
        </Dialog >
    );
}

export default CreateEditDialog;