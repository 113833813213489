import {
  useEffect,
  useState,
} from 'react';

import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { CustomerAction } from 'src/actions/customer.action';
import { ProfileAction } from 'src/actions/profile.action';
import { showToast } from 'src/utils/common';
import { NOTI_TYPES } from 'src/utils/constants';

import {
  Box,
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Autocomplete,
  CardContent,
} from '@material-ui/core';

import { UploadchiadataAction } from 'src/actions/uploadchiadata.action';

import { AppAction } from 'src/actions/app.action';

const ChiadataLookupDetail = (props) => {

  const [assigns, setAssigns] = useState({});
  const dispatch = useDispatch()
  const [members, setMember] = useState([])
  const [children, setChildren] = useState([])
  const { info, permissions, role, leaders, brands, saleman } = useSelector(state => state.profile)
  const { selected } = useSelector(state => state.division)

  const { items } = useSelector(state => state.uploadchiadata)
  const [countAvailable, setCountAvailable] = useState([]);
  const [FileName, setFileName] = useState([]);

  useEffect(() => {
    dispatch(UploadchiadataAction.listNoLimit())
  }, [])

  const handleChangeAssign = (event) => {
    setAssigns({
      ...assigns,
      [event.target.name]: event.target.value
    })
    console.log("assigns:", assigns)
  }

  const handleAssign = () => {
    if (!FileName || FileName == "") {
      showToast(NOTI_TYPES.ERROR, "Chưa chọn file để chia data")
      return;
    }
    
    var options = {
      FileName: FileName,
      assigns: assigns,
    }

    dispatch(UploadchiadataAction.handleFileUserQuantityOption(options))
    showToast(NOTI_TYPES.SUCCESS, "Đã lưu cài đặt")
  }

  useEffect(() => {
    if (brands && brands.length > 0) {
      setMember(brands)
    }
  }, [brands])

  useEffect(() => {
    if (saleman && saleman.length > 0) {
      setChildren(saleman)
    }
  }, [saleman])

  useEffect(() => {
    if (leaders && leaders.length > 0) {
      setChildren(leaders)
    }
  }, [leaders])

  const handleGetLeader = (item) => {
    dispatch(ProfileAction.listLeader(item.id))
  }

  const handleGetSaleman = (item) => {
    dispatch(ProfileAction.listSaleman({ brand: item.id }))
  }

  useEffect(() => {
    dispatch(ProfileAction.listBrand())
  }, [])

  useEffect(() => {
    setAssigns({})

    const fetchOptionDataByFile = async (FileName) => {
      const optionDataByFile = await dispatch(UploadchiadataAction.getOptionDataByFile({FileName: FileName}));
      if (optionDataByFile) {
        //điền option số lượng có sẵn
        if (optionDataByFile) {
          var newOptionFile = {}
          optionDataByFile.forEach((item, index) => {
            newOptionFile[item.username] = item.quantity
            if (item.username == info.username) setCountAvailable(item.quantity)
          });
          setAssigns(newOptionFile);
        }
      }
      console.log(assigns)
      dispatch(AppAction.appLoading(false))
    };

    if (FileName && FileName != "") fetchOptionDataByFile(FileName)
  }, [FileName]) //=> tự động xử lý khi FileName thay đổi

  const renderChildren = (parent) => {
    return (
      children && children.filter((child) => child.brand.id === parent.id).map((item) => (
        <TableRow
          hover
          key={item.id}
        >
          <TableCell sx={{ pl: 8 }}>
            {item.fullname}
          </TableCell>
          <TableCell sx={{ pl: 8 }}>
            <TextField
              label="Số lượng"
              name={item.username}
              onChange={handleChangeAssign}
              type="number"
              defaultValue={0}
              value={assigns[item.username] || ""}
              variant="outlined"
            />
          </TableCell>
          <TableCell >
          </TableCell>
        </TableRow>
      ))
    )
  }

  return (
    <Card style={{ width: "100%"}}>
      <PerfectScrollbar>
        <Box {...props}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start'
            }}
          >
            <Typography variant="h4">
              Chia data cho các chi nhánh
            </Typography>
          </Box>
          <Box sx={{ mt: 3 }}>
            <Card>
              <CardContent>
                <Box sx={{ maxWidth: 500 }}>
                  <Autocomplete
                    disablePortal
                    onChange={(event, newValue) => {
                      if (newValue != null) {
                        setCountAvailable(newValue.tong)
                        setFileName(newValue.FileName)
                      } else {
                        setCountAvailable(0)
                      }
                    }}
                    options={items}
                    getOptionLabel={(option) => option.FileName}
                    fullWidth
                    renderInput={(params) => <TextField
                      {...params}
                      label="Chọn dữ liệu"
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />}
                  />
                </Box>
                <Box sx={{
                  mt: 2,
                }}>
                  <p>Tổng số lượng data: <strong>{countAvailable || 0}</strong></p>
                </Box>
              </CardContent>
            </Card>
          </Box>
        </Box>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Tên chi nhánh
                </TableCell>
                <TableCell>
                  Số lượng
                </TableCell>
                <TableCell>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {members && members.map((item) => [(
                <TableRow
                  hover
                  key={item.id}
                >
                  <TableCell>
                    {item.fullname}
                  </TableCell>
                  <TableCell>
                    <TextField
                      label="Số lượng"
                      name={item.username}
                      onChange={handleChangeAssign}
                      type="number"
                      defaultValue={0}
                      value={assigns[item.username] || ""}
                      variant="outlined"
                      style={role.priority > 2 && role.priority != 7 && { display: 'none' } || { display: 'block' }}
                    />
                  </TableCell>
                  <TableCell >
                    <Button onClick={() => handleGetLeader(item)}>
                      DS Leader
                    </Button>|
                    <Button onClick={() => handleGetSaleman(item)}>
                      DS Saleman
                    </Button>
                  </TableCell>
                </TableRow>
              ), renderChildren(item)])}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <Box sx={{
        display: 'flex',
        justifyContent: 'flex-end'
      }}>
        <Button
          color="primary"
          variant="contained"
          sx={{ m: 2 }}
          onClick={handleAssign}
        >
          Lưu
        </Button>
      </Box>

    </Card>
  );
};

export default ChiadataLookupDetail;
