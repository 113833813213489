import React, { useEffect, useState } from 'react';

import { Search as SearchIcon } from 'react-feather';
import { useDispatch } from 'react-redux';
import { AppAction } from 'src/actions/app.action';
import { KhrAction } from 'src/actions/khr.action';
import * as XLSX from 'xlsx';

import {
  Box,
  Button,
  Card,
  CardContent,
  InputAdornment,
  SvgIcon,
  TextField,
} from '@material-ui/core';

import {
  DEFAULT_ROW_PER_PAGE,
} from 'src/utils/constants';

import CreateEditDialog from './CreateEditDialog';

const KhrToolbar = (props) => {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false);
  const [preview, setPreview] = useState(false);
  const [assign, setAssign] = useState(false);

  const [values, setValues] = useState({
    phone: "",
  });

  useEffect(() => {
    const today = new Date();

    const payload = {
      page: 0,
      limit: DEFAULT_ROW_PER_PAGE,
      phone: "",
    };
    dispatch(KhrAction.updateFilter(payload));
  }, []);

  const fileInput = React.useRef();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleImportFile = (e) => {
    e.preventDefault();
    dispatch(AppAction.appLoading(true))
    const files = e.target.files, f = files[0];
    const reader = new FileReader();
    reader.onload = function (e) {
      let data = e.target.result;
      let readedData = XLSX.read(data, { type: 'binary' });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];
      /* Convert array to json*/
      const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
      const temp = dataParse.splice(1).map((c) => ({
        phone: c[1],
        type: c[2],
        package: c[3],
        registerAt: c[4],
        expiredAt: c[5],
        packageCycle: c[6],
        state: c[7],
        status: "NORMAL",
        packageCanRegister: c[8]
      }));
      dispatch(KhrAction.importData(temp));
      dispatch(AppAction.appLoading(false))
      setPreview(true)
      e.target.value = null;
    };
    reader.readAsBinaryString(f)
  }

  const handleClosePreview = () => {
    fileInput.current.value = null;
    setPreview(false)
  }

  const handleCloseAssign = () => {
    fileInput.current.value = null;
    setAssign(false)
  }

  const handleOpenAssign = () => {
    dispatch(KhrAction.countAssign())
    setAssign(true)
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const onLookup = () => {
    const payload = {
      page: 'no_update',
      limit: 'no_update',
      phone: values.phone,
    };
    dispatch(KhrAction.updateFilter(payload));
    dispatch(KhrAction.list())
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      onLookup();
    }
  };

  return (
    <Box {...props}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <Button
          color="primary"
          variant="contained"
          onClick={handleClickOpen}
        >
          Tạo bản ghi
        </Button>
      </Box>
      <Box sx={{ mt: 3 }}>
        <Card className="filter_card">
          <CardContent>
            <Box sx={{ maxWidth: 500 }}>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        fontSize="small"
                        color="action"
                        >
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                name="phone"
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                value={values.phone}
                placeholder="Tìm SĐT"
                variant="outlined"
                style={{ height: 56, marginTop: 6, width: "200px", marginLeft: 0 }}
              />
              <Button
                color="primary"
                variant="contained"
                onClick={onLookup}
                style={{ height: 56, marginTop: 6, width: "20%", marginLeft: 15 }}
                >
                Tra cứu
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Box>
      <CreateEditDialog open={open} handleClose={handleClose} type={"CREATE"} />
    </Box>
  )
};

export default KhrToolbar;
