import React, { useEffect, useState } from 'react';

import { Search as SearchIcon } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { AppAction } from 'src/actions/app.action';
import { BaodoAction } from 'src/actions/baodo.action';
import * as XLSX from 'xlsx';

import {
  Box,
  Button,
  Card,
  CardContent,
  InputAdornment,
  SvgIcon,
  TextField,
} from '@material-ui/core';

import {
  DEFAULT_ROW_PER_PAGE,
} from 'src/utils/constants';

import CreateEditDialog from './CreateEditDialog';

import moment from 'moment';

import { saveAs } from 'file-saver';

const BaodoToolbar = (props) => {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false);
  const [preview, setPreview] = useState(false);
  const [assign, setAssign] = useState(false);
  const { role, info } = useSelector(state => state.profile);
  const [values, setValues] = useState({
    phone: "",
  });

  useEffect(() => {
    const today = new Date();

    const payload = {
      page: 0,
      limit: DEFAULT_ROW_PER_PAGE,
      phone: "",
    };
    dispatch(BaodoAction.updateFilter(payload));
  }, []);

  const fileInput = React.useRef();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleImportFile = (e) => {
    e.preventDefault();
    dispatch(AppAction.appLoading(true))
    const files = e.target.files, f = files[0];
    const reader = new FileReader();
    reader.onload = function (e) {
      let data = e.target.result;
      let readedData = XLSX.read(data, { type: 'binary' });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];
      /* Convert array to json*/
      const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
      const temp = dataParse.splice(1).map((c) => ({
        phone: c[1],
        type: c[2],
        package: c[3],
        registerAt: c[4],
        expiredAt: c[5],
        packageCycle: c[6],
        state: c[7],
        status: "NORMAL",
        packageCanRegister: c[8]
      }));
      dispatch(BaodoAction.importData(temp));
      dispatch(AppAction.appLoading(false))
      setPreview(true)
      e.target.value = null;
    };
    reader.readAsBinaryString(f)
  }

  const handleClosePreview = () => {
    fileInput.current.value = null;
    setPreview(false)
  }

  const handleCloseAssign = () => {
    fileInput.current.value = null;
    setAssign(false)
  }

  const handleOpenAssign = () => {
    dispatch(BaodoAction.countAssign())
    setAssign(true)
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const onLookup = () => {
    const payload = {
      page: 'no_update',
      limit: 'no_update',
      phone: values.phone,
    };
    dispatch(BaodoAction.updateFilter(payload));
    dispatch(BaodoAction.list())
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      onLookup();
    }
  };

  const xuatFile = async () => {
    if (role.priority == 5) return;
    dispatch(AppAction.appLoading(true))
    try {
      const payload = {
        page: 0,
        limit: 9999999999,
        phone: values.phone,
      };
      dispatch(BaodoAction.updateFilter(payload));
      const response = await dispatch(BaodoAction.list());
      console.log(response)

      const data = [
        [
          '#', 
          'Số điện thoại', 
          'Gói cước', 
          'Giá gói cước', 
          'Kênh', 
          'Ngày thành công',
          'Username NV',
          'Tên NV',
          'Lý do',
          'Trạng thái',
          'Người đặt trạng thái',
          'Ngày tạo',
          'Người phê duyệt',
          'Trạng thái duyệt',
        ],
      ];

      if (typeof response !== 'undefined' && response != null && typeof response.data !== 'undefined' && response.data && typeof response.data.values !== 'undefined' && response.data.values && response.data.values.length > 0) {
        var count = 1;
        response.data.values.forEach((element) => {
          let trangThai = "";
          let trangThaiDuyet = "";

          if (element.trang_thai == '0') trangThai = "Chưa đặt";
          else if (element.trang_thai == 'SUCCESS') trangThai = "Thành công";
          else if (element.trang_thai == 'FAILED') trangThai = "Thất bại";

          if (element.trang_thai_duyet == '1') trangThaiDuyet = "Đã duyệt";
          else if (element.trang_thai_duyet == '2') trangThaiDuyet = "Từ chối";
          
          let pushData = [
            count,
            element.so_dien_thoai,
            element.ten_goi_cuoc,
            element.gia_goi_cuoc,
            element.kenh,
            moment(element.ngay_thanh_cong).format('DD-MM-YYYY HH:mm:ss'),
            element.username_nv,
            element.ten_nv,
            element.ly_do,
            trangThai,
            element.nguoi_dat_trang_thai,
            moment(element.created_at).format('DD-MM-YYYY HH:mm:ss'),
            element.nguoi_phe_duyet,
            trangThaiDuyet,
          ];

          data.push(pushData)
          count++;
        })

        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.aoa_to_sheet(data);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
        
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, 'Bao-cao-bao-do.xlsx');
      }

      dispatch(AppAction.appLoading(false))
    } catch (error) {
      if (typeof error.code !== 'undefined' && typeof error.msg !== 'undefined') alert("[Lỗi]\n"+error.msg)
    }
  }

  return (
    <Box {...props}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <Button
          color="primary"
          variant="contained"
          onClick={handleClickOpen}
        >
          Tạo bản ghi
        </Button>
        {role.priority < 3 && <Button
          color="primary"
          variant="contained"
          onClick={xuatFile}
          style={{marginLeft: 15}}
        >
          Xuất file
        </Button>}
      </Box>
      <Box sx={{ mt: 3 }}>
        <Card className="filter_card">
          <CardContent>
            <Box sx={{ maxWidth: 500 }}>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        fontSize="small"
                        color="action"
                        >
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                name="phone"
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                value={values.phone}
                placeholder="Tìm SĐT"
                variant="outlined"
                style={{ height: 56, marginTop: 6, width: "200px", marginLeft: 0 }}
              />
              <Button
                color="primary"
                variant="contained"
                onClick={onLookup}
                style={{ height: 56, marginTop: 6, width: "20%", marginLeft: 15 }}
                >
                Tra cứu
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Box>
      <CreateEditDialog open={open} handleClose={handleClose} type={"CREATE"} />
    </Box>
  )
};

export default BaodoToolbar;
