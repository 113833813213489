import {
  useEffect,
  useState,
} from 'react';
import {
  Edit3 as Edit3Icon,
  Lock,
  RefreshCcw,
  Trash2 as Trash2Icon,
  Unlock,
} from 'react-feather';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { MfyAction } from 'src/actions/mfy.action';
import {
  CONFIRM_TYPES,
  DEFAULT_ROW_PER_PAGE,
  ROW_PER_PAGE_OPTIONS,
} from 'src/utils/constants';
import { getMfyStatus } from 'src/utils/utils';
import ConfirmDialog from '../ConfirmDialog';
import {
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  IconButton,
  Button,
  Select as Select1,
  MenuItem,
  TextField,
} from '@material-ui/core';
import CreateEditDialog from './CreateEditDialog';

import Select from 'react-select';

import { Search as SearchIcon } from 'react-feather';

import numeral from 'numeral';

import { showToast } from 'src/utils/common';

import axios from '../../utils/axios';
import { toast } from 'react-toastify';
import { GiagoimfyAction } from 'src/actions/giagoimfy.action';

const MfyLookupDetail = ({ ...rest }) => {
  const dispatch = useDispatch()
  const [selectedMfyIds, setSelectedMfyIds] = useState([]);
  const [limit, setLimit] = useState(DEFAULT_ROW_PER_PAGE);
  const [page, setPage] = useState(0);
  const { role, info } = useSelector(state => state.profile);
  const { items, total, filter } = useSelector(state => state.mfy);
  const [confirm, setConfirm] = useState(false);
  const [confirmType, setConfirmType] = useState("");
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [selected, setSelected] = useState({});
  const [open, setOpen] = useState(false);
  const [timer, setTimer] = useState({});
  const [updateSoDienThoaiChu, setUpdateSoDienThoaiChu] = useState({});
  const [ma_goi, setMaGoi] = useState({})
  const [gia_ban_goi, setGiaBanGoi] = useState({})
  const { goiCuocItems } = useSelector(state => state.giagoimfy)
  const { giaBanGoiItems } = useState([1, 2, 3])
  let timerVar;

  useEffect(() => {
    const payload = {
      ...filter,
      limit: limit,
      page: page,
    };

    dispatch(MfyAction.updateFilter(payload));
    dispatch(MfyAction.list())
    if (role.priority < 3) dispatch(GiagoimfyAction.listNoLimit());
  }, [page, limit])

  useEffect(() => {
    timerCountdown();

    // Clean up the interval when the component unmounts
    return () => clearInterval(timerVar);
  }, [items]);

  const timerCountdown = () => {
    clearInterval(timerVar);

    timerVar = setInterval(() => {
      if (items.length > 0) {
        const updatedTimer = {};

        items.forEach((mfy) => {
          if (mfy.trang_thai_yeu_cau == 'cho_soan_y') {
            const theTime = mfy.thoi_diem_thay_doi_trang_thai ? mfy.thoi_diem_thay_doi_trang_thai : mfy.created_at;

            const currentDate = new Date();
            const targetDate = new Date(theTime);

            // Add 10 minutes to targetDate
            targetDate.setMinutes(targetDate.getMinutes() + 10);

            const difference = targetDate.getTime() - currentDate.getTime();
            let timeRemaining = Math.max(0, Math.floor(difference / 1000));

            if (timeRemaining <= 0) {
              timeRemaining = 0;
              dispatch(MfyAction.setTrangThai(mfy.id, { trang_thai: 'huy_qua_han', is_auto: '1' }));
            }
            
            let minutes = Math.floor(timeRemaining / 60);
            let seconds = timeRemaining % 60;

            if (minutes < 10) minutes = '0' + minutes;
            if (seconds < 10) seconds = '0' + seconds;

            updatedTimer[mfy.id] = `${minutes}:${seconds}`;
          } else if (mfy.trang_thai_yeu_cau == 'cho_soan_y_lan_2') {
            const theTime = mfy.thoi_diem_thay_doi_trang_thai ? mfy.thoi_diem_thay_doi_trang_thai : mfy.created_at;

            const currentDate = new Date();
            const targetDate = new Date(theTime);

            // Add 10 minutes to targetDate
            targetDate.setMinutes(targetDate.getMinutes() + 10);

            const difference = targetDate.getTime() - currentDate.getTime();
            let timeRemaining = Math.max(0, Math.floor(difference / 1000));

            if (timeRemaining <= 0) {
              timeRemaining = 0;
              dispatch(MfyAction.setTrangThai(mfy.id, { trang_thai: 'huy_qua_han_lan_2', is_auto: '1' }));
            }
            
            let minutes = Math.floor(timeRemaining / 60);
            let seconds = timeRemaining % 60;

            if (minutes < 10) minutes = '0' + minutes;
            if (seconds < 10) seconds = '0' + seconds;

            updatedTimer[mfy.id] = `${minutes}:${seconds}`;
          }
        });

        setTimer(updatedTimer);
      }
    }, 1000);
  };

  const handleSelectAll = (event) => {
    let newSelectedMfyIds;

    if (event.target.checked) {
      newSelectedMfyIds = items.map((mfy) => mfy.id);
    } else {
      newSelectedMfyIds = [];
    }

    setSelectedMfyIds(newSelectedMfyIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedMfyIds.indexOf(id);
    let newSelectedMfyIds = [];

    if (selectedIndex === -1) {
      newSelectedMfyIds = newSelectedMfyIds.concat(selectedMfyIds, id);
    } else if (selectedIndex === 0) {
      newSelectedMfyIds = newSelectedMfyIds.concat(selectedMfyIds.slice(1));
    } else if (selectedIndex === selectedMfyIds.length - 1) {
      newSelectedMfyIds = newSelectedMfyIds.concat(selectedMfyIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedMfyIds = newSelectedMfyIds.concat(
        selectedMfyIds.slice(0, selectedIndex),
        selectedMfyIds.slice(selectedIndex + 1)
      );
    }

    setSelectedMfyIds(newSelectedMfyIds);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleConfirmOpen = (type, mfy) => {
    setSelected(mfy);
    setConfirmType(type);
    if (confirmType === CONFIRM_TYPES.DELETE) {
      setTitle("Xoá bản ghi")
      setContent(`Xác nhận xoá bản ghi `)
    }
    setConfirm(true);
  };

  const onConfirm = () => {
    setConfirm(false);
    if (confirmType === CONFIRM_TYPES.DELETE) {
      dispatch(MfyAction.del(selected.id))
      return;
    }
  }

  const handleConfirmClose = () => {
    setConfirm(false);
  };

  const onSelected = (id) => {
    dispatch(MfyAction.findOne(id));
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const approve = async (id, trang_thai) => {
    const question = trang_thai == 1 ? "Xác nhận duyệt đơn này?" : "Xác nhận từ chối đơn này?" ;
    if (window.confirm(question)) {
      try {
        const response = await dispatch(MfyAction.approve(id, { trang_thai }));
        if (typeof response.channel !== 'undefined') alert("[Thông báo từ '"+response.channel+"']\n"+response.message+(response.status != "Unknown" ? (" ("+response.status+")") : ""));
        else if (typeof response.code !== 'undefined' && typeof response.msg !== 'undefined') alert("[Lỗi]\n"+response.msg)
      } catch (error) {
        if (typeof error.code !== 'undefined' && typeof error.msg !== 'undefined') alert("[Lỗi]\n"+error.msg)
      }
    } else {
      
    }
  };

  const copyToClipboard = (content) => {
    navigator.clipboard.writeText(content)
      .then(() => {
        console.log('String copied to clipboard:', content);
        showToast('success', `Đã copy: ${content}`)
      })
      .catch((error) => {
        console.error('Unable to copy string:', error);
        showToast('error', `Lỗi không thể copy: ${content}`)
      });
  }

  const setColor = (value) => {
    if (value == 'cho_soan_y') return 'blue';
    else if (value == 'cho_soan_y_lan_2') return 'blue';
    else if (value == 'qua_han') return 'red';
    else if (value == 'qua_han_lan_2') return 'red';
    else if (value == 'dang_cho_xu_ly') return 'blue';
    else if (value == 'thanh_cong') return 'green';
    else if (value == 'that_bai') return 'red';
    else if (value == 'dang_cho_duyet') return 'blue';
    else if (value == 'duyet') return 'green';
    else if (value == 'duyet_lan_2') return 'green';
    else if (value == 'da_soan_y_lan_1') return 'orange';
    else if (value == 'dang_ky_thanh_cong') return 'green';
    else if (value == 'thu_hoi') return 'red';
    else if (value == 'huy_qua_han') return 'red';
    else if (value == 'huy_qua_han_lan_2') return 'red';
    else if (value == 'da_chuyen_khoan') return 'green';
    else if (value == 'da_thu_tien_mat') return 'purple';
    else if (value == 'chua_thu_tien') return 'red';
    else if (value == 'yeu_cau_thu_hoi') return 'orange';
    else if (value == 'dang_cho_duyet') return 'blue';
    else if (value == 'da_duyet') return 'green';
    else if (value == 'tu_choi') return 'red';
    else return 'black';
  }

  const setTrangThai = async (id, value) => {
    const question = "Xác nhận thay đổi trạng thái đơn này đơn này?" ;
    if (window.confirm(question)) {
      try {
        const response = await dispatch(MfyAction.setTrangThai(id, { trang_thai: value }));
        if (typeof response.channel !== 'undefined') alert("[Thông báo từ '"+response.channel+"']\n"+response.message+(response.status != "Unknown" ? (" ("+response.status+")") : ""));
        else if (typeof response.code !== 'undefined' && typeof response.msg !== 'undefined') alert("[Lỗi]\n"+response.msg)
      } catch (error) {
        if (typeof error.code !== 'undefined' && typeof error.msg !== 'undefined') alert("[Lỗi]\n"+error.msg)
      }
    } else {
      
    }
  }

  const setTrangThaiThuTien = async (id, value) => {
    const question = "Xác nhận thay đổi trạng thái đơn này đơn này?" ;
    if (window.confirm(question)) {
      try {
        const response = await dispatch(MfyAction.setTrangThaiThuTien(id, { trang_thai_thu_tien: value }));
        if (typeof response.channel !== 'undefined') alert("[Thông báo từ '"+response.channel+"']\n"+response.message+(response.status != "Unknown" ? (" ("+response.status+")") : ""));
        else if (typeof response.code !== 'undefined' && typeof response.msg !== 'undefined') alert("[Lỗi]\n"+response.msg)
      } catch (error) {
        if (typeof error.code !== 'undefined' && typeof error.msg !== 'undefined') alert("[Lỗi]\n"+error.msg)
      }
    } else {
      
    }
  }

  const xoaTruyThu = async (id) => {
    const question = "Xóa truy thu?" ;
    if (window.confirm(question)) {
      try {
        const response = await dispatch(MfyAction.xoaTruyThu(id));
        if (typeof response.channel !== 'undefined') alert("[Thông báo từ '"+response.channel+"']\n"+response.message+(response.status != "Unknown" ? (" ("+response.status+")") : ""));
        else if (typeof response.code !== 'undefined' && typeof response.msg !== 'undefined') alert("[Lỗi]\n"+response.msg)
      } catch (error) {
        if (typeof error.code !== 'undefined' && typeof error.msg !== 'undefined') alert("[Lỗi]\n"+error.msg)
      }
    } else {
      
    }
  }

  const setTrangThaiDuyetThongTin = async (id, value) => {
    const question = "Xác nhận thay đổi trạng thái đơn này đơn này?" ;
    if (window.confirm(question)) {
      try {
        const response = await dispatch(MfyAction.setTrangThaiDuyetThongTin(id, { trang_thai_duyet_thong_tin: value }));
        if (typeof response.channel !== 'undefined') alert("[Thông báo từ '"+response.channel+"']\n"+response.message+(response.status != "Unknown" ? (" ("+response.status+")") : ""));
        else if (typeof response.code !== 'undefined' && typeof response.msg !== 'undefined') alert("[Lỗi]\n"+response.msg)
      } catch (error) {
        if (typeof error.code !== 'undefined' && typeof error.msg !== 'undefined') alert("[Lỗi]\n"+error.msg)
      }
    } else {
      
    }
  }

  const handleSoDienThoaiChuChange = (event, so_dien_thoai_chu, id) => {
    so_dien_thoai_chu = so_dien_thoai_chu.trim();
    if (so_dien_thoai_chu != '') {
      setUpdateSoDienThoaiChu({
        ...updateSoDienThoaiChu,
        [id]: so_dien_thoai_chu,
      });
    }
  }

  const handleSoDienThoaiChuChangeConfirm = async (event, so_dien_thoai_chu, id) => {
    if (event.key === 'Enter') {
      so_dien_thoai_chu = so_dien_thoai_chu.trim();
      if (so_dien_thoai_chu != '') {
        handleSoDienThoaiChuChange(event, so_dien_thoai_chu, id)
        const response = await dispatch(MfyAction.updateSoDienThoaiChu(so_dien_thoai_chu, id));
        if (response.result == 'success') {
          showToast('success', `${response.mes}`)
        } else {
          showToast('error', `${response.mes}`)
        }
      }
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      axios.get(`/api/v1/mfy/nhacNhanVien`, {})
      .then((rs) => {
        if (rs.data.result == 'success') {
          const phone = rs.data.data.phone_number;
          const yeuCau = rs.data.data.yeu_cau

          let message = ``;
          if (yeuCau == 'can_duyet' && info.role.priority < 3) message = `Đơn ${phone} cần duyệt. Vui lòng kiểm tra!`
          else if (yeuCau == 'soan_y') message = `Đơn ****${phone.slice(-5)} đang chờ soạn Y lần 1. Vui lòng kiểm tra!`
          else if (yeuCau == 'soan_y_lan_2') message = `Đơn ****${phone.slice(-5)} đang chờ soạn Y lần 2. Vui lòng kiểm tra!`
          
          if (message != ``) {
            console.log(message);
            toast.dismiss();
            toast.warning(message, {
              position: "top-left",
              autoClose: 30000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              progress: undefined,
              style: {
                color: 'rgba(0,0,0,0.8)',
                fontWeight: 'bold',
              }
            });
          }
        }
      }).catch((error) => {
        console.log(error)
      })
    }, 10000)

    return () => clearInterval(intervalId);
  }, [])

  const setMaGoi1 = (package_code, id) => {
    console.log(package_code, id)
    package_code = package_code.trim();
    if (package_code != '') {
      setMaGoi({
        ...ma_goi,
        [id]: package_code,
      });
      dispatch(MfyAction.updatePackageCode(package_code, id));
    }
  };

  const setGiaBanGoi1 = (gia_ban_goi, id) => {
    console.log(gia_ban_goi, id)
    if (gia_ban_goi != '') {
      setGiaBanGoi({
        ...gia_ban_goi,
        [id]: gia_ban_goi,
      });
      dispatch(MfyAction.updateGiaBanGoi(gia_ban_goi, id));
    }
  };

  const moiTuDong = async (id) => {
    const response = await dispatch(MfyAction.moiTuDong(id));
    if (response.result == 'success') {
      showToast('success', `${response.mes}`)
    } else {
      showToast('error', `${response.mes}`)
    }
  }

  return (
    <Card {...rest} style={{ width: "100%"}}>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  #
                </TableCell>
                {(role.priority < 3) && <TableCell>
                  Thuê bao chủ
                </TableCell>}
                <TableCell>
                  Thuê bao
                </TableCell>
                <TableCell>
                  Nhân viên
                </TableCell>
                <TableCell>
                  Mã gói
                </TableCell>
                <TableCell>
                  Trạng thái yêu cầu
                </TableCell>
                <TableCell>
                  Trạng thái
                </TableCell>
                <TableCell>
                  Trạng thái thu tiền
                </TableCell>
                {/* <TableCell>
                  Trạng thái duyệt
                </TableCell> */}
                <TableCell>
                  Giá bán gói
                </TableCell>
                <TableCell>
                  Doanh thu
                </TableCell>
                <TableCell>
                  Doanh thu tính lương
                </TableCell>
                <TableCell>
                  Truy thu
                </TableCell>
                <TableCell>
                  Ngày lên đơn
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.slice(page * limit, page * limit + limit).map((mfy, index) => (
                <TableRow
                  hover
                  key={mfy.id}
                  selected={selectedMfyIds.indexOf(mfy.id) !== -1}
                >
                  <TableCell>
                    {page * limit + index + 1}
                  </TableCell>
                  {(role.priority < 3) && <TableCell>
                    <TextField
                      name={'so_dien_thoai_chu' + '_' + mfy.id}
                      onChange={(e) => handleSoDienThoaiChuChange(e, e.target.value, mfy.id)}
                      onKeyDown={(e) => handleSoDienThoaiChuChangeConfirm(e, e.target.value, mfy.id)}
                      value={updateSoDienThoaiChu[mfy.id] || mfy.so_dien_thoai_chu}
                      placeholder=""
                      variant="outlined"
                      style={{ height: 56, width: 150, marginLeft: 0 }}
                    />
                  </TableCell>}
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {mfy.so_dien_thoai && <Button style={{padding: 0}} onClick={() => copyToClipboard(mfy.so_dien_thoai.slice(-9))}>
                        {(role.priority < 3) ? mfy.so_dien_thoai.slice(-9) : ('****' + mfy.so_dien_thoai.slice(-5))}
                      </Button>}
                    </Typography>
                    {role.priority < 3 && <>
                      <br></br>
                      <Button style={{margin: 0, padding: 0, color: 'green'}} onClick={() => moiTuDong(mfy.id)}>
                        MỜI TỰ ĐỘNG
                      </Button>
                    </>}
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {mfy.username_nv} - {mfy.ten_nv}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {role.priority < 3 && <div style={{ width: '150px', zIndex: '10' }} className="full-width-select">
                      <Select
                          styles={{
                          control: (provided) => ({
                              ...provided,
                              height: 56,
                              fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                          }),
                          option: (provided) => ({
                              ...provided,
                              fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                          }),
                          }}
                          options={goiCuocItems}
                          getOptionLabel={(itemShow) => `${itemShow.ten_goi_cuoc}`}
                          placeholder={ma_goi[mfy.id] || mfy.ma_goi || "Chọn gói..."}
                          components={{
                          DropdownIndicator: () => <SearchIcon size={16} color="action" />,
                          IndicatorSeparator: null,
                          }}
                          value={ma_goi[mfy.id] || mfy.ma_goi || null}
                          onChange={(newValue) => setMaGoi1(newValue.ten_goi_cuoc, mfy.id)}
                          isSearchable
                      />
                    </div>}
                    {role.priority > 2 && mfy.ma_goi}
                  </TableCell>
                  <TableCell>
                    <Typography
                      color={setColor(mfy.trang_thai_yeu_cau)}
                      variant="body1"
                    >
                      {mfy.trang_thai_yeu_cau == 'cho_soan_y' && "Chờ soạn Y lần 1"}
                      {mfy.trang_thai_yeu_cau == 'qua_han' && "Quá hạn lần 1"}
                      {mfy.trang_thai_yeu_cau == 'da_soan_y_lan_1' && "Đã soạn Y lần 1"}
                      {mfy.trang_thai_yeu_cau == 'cho_soan_y_lan_2' && "Chờ soạn Y lần 2"}
                      {mfy.trang_thai_yeu_cau == 'qua_han_lan_2' && "Quá hạn lần 2"}
                      {mfy.trang_thai_yeu_cau == 'dang_cho_xu_ly' && "Đang chờ xử lý"}
                      {mfy.trang_thai_yeu_cau == 'thanh_cong' && "Thành công"}
                      {mfy.trang_thai_yeu_cau == 'thu_hoi' && "Thu hồi"}

                      {(mfy.trang_thai_yeu_cau == 'cho_soan_y' || mfy.trang_thai_yeu_cau == 'cho_soan_y_lan_2') && <><br></br><br></br><span style={{color: 'red'}}>{typeof timer[mfy.id] !== 'undefined' ? timer[mfy.id] : ''}</span></>}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {(role.priority < 3) && <><Select1 style={{ fontSize: "14px", width: "100%", marginBottom: '15px', color: setColor(mfy.trang_thai)}} variant="outlined" value={mfy.trang_thai} onChange={(e) => setTrangThai(mfy.id, e.target.value)}>
                      <MenuItem style={{ color: setColor('dang_cho_duyet') }} value="dang_cho_duyet">Đang chờ duyệt</MenuItem>
                      <MenuItem style={{ color: setColor('duyet') }} value="duyet">Duyệt lần 1</MenuItem>
                      <MenuItem style={{ color: setColor('duyet_lan_2') }} value="duyet_lan_2">Duyệt lần 2</MenuItem>
                      <MenuItem style={{ color: setColor('da_soan_y_lan_1') }} value="da_soan_y_lan_1">Đã soạn Y lần 1</MenuItem>
                      <MenuItem style={{ color: setColor('dang_ky_thanh_cong') }} value="dang_ky_thanh_cong">Đăng ký thành công</MenuItem>
                      <MenuItem style={{ color: setColor('thu_hoi') }} value="thu_hoi">Thu hồi</MenuItem>
                      <MenuItem style={{ color: setColor('huy_qua_han') }} value="huy_qua_han">Hủy quá hạn lần 1</MenuItem>
                      <MenuItem style={{ color: setColor('huy_qua_han_lan_2') }} value="huy_qua_han_lan_2">Hủy quá hạn lần 2</MenuItem>
                    </Select1></>}
                    {(role.priority > 2) &&<Typography
                      color={setColor(mfy.trang_thai)}
                      variant="body1"
                    >
                      {mfy.trang_thai == 'dang_cho_duyet' && "Đang chờ duyệt"}
                      {mfy.trang_thai == 'duyet' && "Duyệt lần 1"}
                      {mfy.trang_thai == 'duyet_lan_2' && "Duyệt lần 2"}
                      {mfy.trang_thai == 'da_soan_y_lan_1' && "Đã soạn Y lần 1"}
                      {mfy.trang_thai == 'dang_ky_thanh_cong' && "Đăng ký thành công"}
                      {mfy.trang_thai == 'thu_hoi' && "Thu hồi"}
                      {mfy.trang_thai == 'huy_qua_han' && "Hủy quá hạn lần 1"}
                      {mfy.trang_thai == 'huy_qua_han_lan_2' && "Hủy quá hạn lần 2"}
                    </Typography>}
                    {role.priority < 3 && (mfy.username_thay_doi_trang_thai + ' (' + moment(mfy.thoi_diem_thay_doi_trang_thai ? mfy.thoi_diem_thay_doi_trang_thai : mfy.created_at).format('DD-MM-YYYY HH:mm:ss') + ')') }
                  </TableCell>
                  <TableCell>
                    {role.priority < 5 && <><Select1 style={{ fontSize: "14px", width: "100%", marginBottom: '15px', color: setColor(mfy.trang_thai_thu_tien)}} variant="outlined" value={mfy.trang_thai_thu_tien} onChange={(e) => setTrangThaiThuTien(mfy.id, e.target.value)}>
                      <MenuItem style={{ color: setColor('da_chuyen_khoan') }} value="da_chuyen_khoan">Đã chuyển khoản</MenuItem>
                      {/* <MenuItem style={{ color: setColor('da_thu_tien_mat') }} value="da_thu_tien_mat">Đã thu tiền mặt</MenuItem> */}
                      <MenuItem style={{ color: setColor('chua_thu_tien') }} value="chua_thu_tien">Chưa thu tiền</MenuItem>
                      <MenuItem style={{ color: setColor('yeu_cau_thu_hoi') }} value="yeu_cau_thu_hoi">Yêu cầu thu hồi</MenuItem>
                    </Select1></>}
                    {role.priority >= 5 &&<Typography
                      color={setColor(mfy.trang_thai_thu_tien)}
                      variant="body1"
                    >
                      {mfy.trang_thai_thu_tien == 'da_chuyen_khoan' && "Đã chuyển khoản"}
                      {mfy.trang_thai_thu_tien == 'da_thu_tien_mat' && "Đã thu tiền mặt"}
                      {mfy.trang_thai_thu_tien == 'chua_thu_tien' && "Chưa thu tiền"}
                      {mfy.trang_thai_thu_tien == 'yeu_cau_thu_hoi' && "Yêu cầu thu hồi"}
                    </Typography>}
                    {role.priority < 3 && (mfy.username_thay_doi_trang_thai_thu_tien + ' (' + moment(mfy.thoi_diem_thay_doi_trang_thai_thu_tien ? mfy.thoi_diem_thay_doi_trang_thai_thu_tien : mfy.created_at).format('DD-MM-YYYY HH:mm:ss') + ')') }
                  </TableCell>
                  {/* <TableCell>
                    {role.priority < 5 && <><Select1 style={{ fontSize: "14px", width: "100%", marginBottom: '15px', color: setColor(mfy.trang_thai_duyet_thong_tin)}} variant="outlined" value={mfy.trang_thai_duyet_thong_tin} onChange={(e) => setTrangThaiDuyetThongTin(mfy.id, e.target.value)}>
                      <MenuItem style={{ color: setColor('dang_cho_duyet') }} value="dang_cho_duyet">Đang chờ duyệt</MenuItem>
                      <MenuItem style={{ color: setColor('da_duyet') }} value="da_duyet">Đã duyệt</MenuItem>
                      <MenuItem style={{ color: setColor('tu_choi') }} value="tu_choi">Từ chối</MenuItem>
                    </Select1></>}
                    {role.priority >= 5 &&<Typography
                      color={setColor(mfy.trang_thai_duyet_thong_tin)}
                      variant="body1"
                    >
                      {mfy.trang_thai_duyet_thong_tin == 'dang_cho_duyet' && "Đang chờ duyệt"}
                      {mfy.trang_thai_duyet_thong_tin == 'da_duyet' && "Đã duyệt"}
                      {mfy.trang_thai_duyet_thong_tin == 'tu_choi' && "Từ chối"}
                    </Typography>}
                    {role.priority < 3 && (mfy.username_duyet_trang_thai_duyet_thong_tin + ' (' + moment(mfy.thoi_diem_duyet_thong_tin ? mfy.thoi_diem_duyet_thong_tin : mfy.created_at).format('DD-MM-YYYY HH:mm:ss') + ')') }
                  </TableCell> */}
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {(role.priority < 3) && <>
                        <Select1 style={{ fontSize: "14px", width: "100%", marginBottom: '15px'}} variant="outlined" value={mfy.gia_ban_goi} onChange={(e) => setGiaBanGoi1(e.target.value, mfy.id)}>
                          <MenuItem value="1">T1</MenuItem>
                          <MenuItem value="2">T2</MenuItem>
                          <MenuItem value="3">T3</MenuItem>
                        </Select1>
                      </>}
                      {role.priority > 2 && <>
                        {mfy.gia_ban_goi == 1 && "T1"}
                        {mfy.gia_ban_goi == 2 && "T2"}
                        {mfy.gia_ban_goi == 3 && "T3"}
                      </>}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {numeral(mfy.doanh_thu).format('0,0') + " "}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {numeral(mfy.doanh_thu_tinh_luong).format('0,0')} ({numeral(mfy.ti_le_doanh_thu).format('0,0')}%)
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {numeral(mfy.truy_thu).format('0,0')}
                      {/* {mfy.truy_thu > 0 && <> (Khách hủy {mfy.truy_thu / 30000} lần)</>} */}
                      {mfy.truy_thu > 0 && <Button style={{padding: 0}} onClick={() => xoaTruyThu(mfy.id)}>
                        Xóa truy thu
                      </Button>}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {moment(mfy.created_at).format('DD-MM-YYYY HH:mm:ss')}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={total}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
      />
      <CreateEditDialog open={open} handleClose={handleClose} type={"EDIT"} />
      <ConfirmDialog open={confirm} title={title} content={content || `Xác nhận xoá bản ghi `} highlight={selected.ten_goi_cuoc} handleClose={handleConfirmClose} handleConfirm={onConfirm} />
    </Card>
  );
};

export default MfyLookupDetail;
